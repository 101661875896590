import React, { useState , useCallback, useEffect} from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import { useDispatch, useSelector } from 'react-redux';
import {Box, CircularProgress, MenuItem, Switch, Select, IconButton} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { GridColumnMenu, GridToolbarQuickFilter, gridClasses, GridRenderEditCellParams, GridColDef, useGridApiRef } from '@mui/x-data-grid';
import { grey } from '@mui/material/colors';
import { styled } from '@mui/material/styles';
import { DataGrid, GridEditSingleSelectCellProps, useGridApiContext, GridEditSingleSelectCell } from '@mui/x-data-grid';
import { setMessageSnackbarStateState, setExternalUserManagementModalState } from '../../../../redux/slices/common';
import { setMessage } from '../../../../redux/slices/message';
import StripeService from "../../../../services/api/stripe.service";
import CreditCardIcon from '@mui/icons-material/CreditCard';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';


// const roles = ['Market', 'Finance', 'Development'];
// const randomRole = () => {
//   return randomArrayItem(roles);
// };

function QuickSearchToolbar() {

    const { currentTheme } = useSelector((state) => state.theme);

    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'flex-end', // Align to the right corner
                p: 0.5,
                pb: 0,
                '& .MuiInputBase-input': {
                    color: currentTheme === 'dark' ? '#ffffff' : undefined // Font color white if dark theme
                },
                '& .MuiOutlinedInput-root': {
                    borderRadius: 'none', // Example: Remove border radius
                },
            }}
        >
            <GridToolbarQuickFilter sx={{ border: "none", borderBottom: 0, padding: 0 }} autoComplete='off' />
        </Box>
    );
}

function CustomColumnMenu(props) {
    return (
        <GridColumnMenu
            {...props}
            slots={{
                // Hide `columnMenuFilterItem`
                columnMenuFilterItem: null,
            }}
        />
    );
}

const useFakeMutation = () => {
    return React.useCallback(
        (user) =>
            new Promise((resolve, reject) => {
                setTimeout(() => {
                    if (user.name?.trim() === '') {
                        reject(new Error("Error while saving user: name can't be empty."));
                    } else {
                        resolve({ ...user, name: user.name?.toUpperCase() });
                    }
                }, 200);
            }),
        [],
    );
};

function TransactionReport() {

    const { user } = useSelector((state) => state.auth.auth);
    const { selectedCountry } = useSelector((state) => state.auth);
    const { currentTheme } = useSelector((state) => state.theme);
    const currentThemeColor = currentTheme === 'light' ? '#010412' : '#FFF';
    const dispatch = useDispatch();
    const [loadingState, setLoadingState] = useState({});

    const [tableData, setTableData] = useState([]);
    // const [tierName, setTierName] = useState('');
    // const [monthValue, setMonthValue] = useState('');

    const clientTransactionReport = async () => {
        try {
            if (user) {

                const queryCalcParams = [
                    `userId=${user.id}`
                ];

                const data = await StripeService.getTransactionReport(queryCalcParams.join('&'));

                if (data?.results?.length) {
                    // const convertedData = data.results.map((item) => ({
                    //   ...item,
                    //   dateOfExpiring: new Date(item.dateOfExpiring),
                    //   dateOfApproval: new Date(item.dateOfApproval)
                    // }));
                    setTableData(data.results);
                    console.log(tableData);
                } else {
                    setTableData([]);
                }
            }
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {

        clientTransactionReport();
        //apiRef.current.setRows(tableData);
    }, []);



    // const optionStyle = {
    //     minWidth: '100px',
    //     maxWidth: '100px',
    //     minHeight: '52px',
    //     maxHeight: '52px'
    // };
    //
    // const formatDateForQueryParam = (date) => {
    //     const year = date.getFullYear();
    //     const month = String(date.getMonth() + 1).padStart(2, '0'); // Adding 1 because months are 0-based
    //     const day = String(date.getDate()).padStart(2, '0');
    //     const hours = String(date.getHours()).padStart(2, '0');
    //     const minutes = String(date.getMinutes()).padStart(2, '0');
    //     const seconds = String(date.getSeconds()).padStart(2, '0');
    //
    //     return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    // };


    const processRowUpdate = (newRow) => {
        const updatedRows = tableData.map((row) => (row.id === newRow.id ? newRow : row));
        setTableData(updatedRows);
        return newRow;
    };

    // Handle row update error
    const handleProcessRowUpdateError = (error) => {
        console.error(error);
    };

    const genericPreProcessEditCellProps = (params, options) => {
        const value = params.props.value;
        let hasError = false;
        let errorMessage = '';

        if (isNaN(value)) {

            hasError = true;
            errorMessage = "Input is not a number";
        } else if (value === '') {

            hasError = true;
            errorMessage = "Input cannot be empty";
        } else if (Number(value) < 0) {

            hasError = true;
            errorMessage = "Negative values are not allowed";
        } else if (options?.max !== undefined && Number(value) >= options.max) {

            hasError = true;
            errorMessage = `Value must be less than ${options.max}`;
        }

        if (hasError) {
            dispatch(setMessage({
                type: "error",
                title: errorMessage,
            }));
            dispatch(setMessageSnackbarStateState(true));
        } else if (options?.incrementBy) {
            // If no error, increment the number by the specified increment value
            params.props.value = Number(value) + options.incrementBy;
        }

        return { ...params.props, error: hasError };
    };


    const genericFloatPreProcessEditCellProps = (params) => {
        const { props } = params;
        let value = props.value;
        let hasError = false;
        let errorMessage = '';
        // Check if the value is a valid number and allow decimal values

        if (isNaN(value)) {

            hasError = true;
            errorMessage = "Input is not a number";
        } else if (value === '') {

            hasError = true;
            errorMessage = "Input cannot be empty";
        }  else if (value < 0) { // Check if the value is negative

            hasError = true;
            errorMessage = "Input cannot be negative";
        }



        if (hasError) {
            dispatch(setMessage({
                type: "error",
                title: errorMessage,
            }));
            dispatch(setMessageSnackbarStateState(true));
        }


        return { ...props, value, hasError };
    };

    const formatDecimal = (value) => {
        if (value !== null && value !== undefined) {
            return value.toFixed(4);
        }
        return '';
    };

    const columns: GridColDef[] = [
        {
            field: 'transactionId',
            headerName: 'Transaction Id',
            width: 500,
            align: 'center',
            headerAlign: 'center',
        },
        {
            field: 'typeOfPurchase',
            headerName: 'Transaction Details',
            width: 200,
            align: 'center',
            headerAlign: 'center',
        },
        {
            field: 'currentAmount',
            headerName: 'Transaction Amount',
            width: 150,
            align: 'center',
            headerAlign: 'center',
            type: 'mail',
            valueFormatter: (params) => {
                // Ensure the value exists and is formatted as a currency
                const amount = params.value || 0;
                return `$ ${amount}`;
            },
        },
        {
            field: 'transactionDate',
            headerName: 'Transaction Date',
            width: 150,
            align: 'center',
            headerAlign: 'center',
            renderCell: (params) => {
                const dateObj = new Date(params.row.transactionDate);
                const formattedDate = `${dateObj.getFullYear()}-${String(dateObj.getMonth() + 1).padStart(2, '0')}-${String(dateObj.getDate()).padStart(2, '0')} 
                               ${String(dateObj.getHours()).padStart(2, '0')}:${String(dateObj.getMinutes()).padStart(2, '0')}:${String(dateObj.getSeconds()).padStart(2, '0')}`;

                return (
                    <div style={{ color: currentTheme === 'light' ? '#000000' : '#fff' }}>
                        {formattedDate}
                    </div>
                );
            },
        },
        {
            field: 'paymentType',
            headerName: 'Transaction Type',
            width: 150,
            align: 'center',
            headerAlign: 'center',
            renderCell: (params) => {
                // Determine the icon based on paymentType value
                let icon;
                switch (params.value) {
                    case 'Debit':
                        icon = <CreditCardIcon style={{ color: 'red' }} />;
                        break;
                    case 'Credit':
                        icon = <CreditCardIcon style={{ color: 'green' }} />;
                        break;
                    case 'Balance Rollover':
                        icon = <AccountBalanceIcon style={{ color: currentTheme === 'light' ?  '#000000' : '#fff' }} />;
                        break;
                    default:
                        icon = null;
                }

                return (
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        {icon}
                    </div>
                );
            },
        },
    ];

    const handleClose = (e, reason) => {
        if (reason !== "backdropClick") {
            dispatch(setExternalUserManagementModalState(false));
        }
    };

    const isCellEditable = (params) => {

        // Disable editing for specific fields when the plan is active
        if (params.row.isActivePlan &&
            (params.field === 'creditLimit' || params.field === 'audienceCount' || params.field === 'cardCount' || params.field === 'audienceCost' || params.field === 'cardCost' || params.field === 'planType' || params.field === 'validity')
        ) {
            return false;
        }

        // Enable 'creditLimit' only when planType is 'Enterprise' and  planType is 'Trial'
        if ((params.row.planType !== 'Enterprise' || params.row.planType === 'Trial') && (params.field === 'audienceCost' || params.field === 'cardCost' || params.field === 'creditLimit')) {
            return false;
        }

        // Default: allow editing
        return true;
    };



    const handleBackdropClick = (event) => {
        event.stopPropagation();
        return false;
    };

    const getRowSpacing = React.useCallback((params) => {
        return {
            top: params.isFirstVisible ? 0 : 5,
            bottom: params.isLastVisible ? 0 : 5,
        };
    }, []);

    const StyledGridOverlay = styled('div')(({ theme }) => ({
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        '& .ant-empty-img-1': {
            fill: theme.palette.mode === 'light' ? '#aeb8c2' : '#262626',
        },
        '& .ant-empty-img-2': {
            fill: theme.palette.mode === 'light' ? '#f5f5f7' : '#595959',
        },
        '& .ant-empty-img-3': {
            fill: theme.palette.mode === 'light' ? '#dce0e6' : '#434343',
        },
        '& .ant-empty-img-4': {
            fill: theme.palette.mode === 'light' ? '#fff' : '#1c1c1c',
        },
        '& .ant-empty-img-5': {
            fillOpacity: theme.palette.mode === 'light' ? '0.8' : '0.08',
            fill: theme.palette.mode === 'light' ? '#f5f5f5' : '#fff',
        },
    }));

    function CustomNoRowsOverlay() {
        return (
            <StyledGridOverlay>
                <svg
                    style={{ flexShrink: 0 }}
                    width="240"
                    height="200"
                    viewBox="0 0 184 152"
                    aria-hidden
                    focusable="false"
                >
                    <g fill="none" fillRule="evenodd">
                        <g transform="translate(24 31.67)">
                            <ellipse
                                className="ant-empty-img-5"
                                cx="67.797"
                                cy="106.89"
                                rx="67.797"
                                ry="12.668"
                            />
                            <path
                                className="ant-empty-img-1"
                                d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
                            />
                            <path
                                className="ant-empty-img-2"
                                d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
                            />
                            <path
                                className="ant-empty-img-3"
                                d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
                            />
                        </g>
                        <path
                            className="ant-empty-img-3"
                            d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
                        />
                        <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
                            <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
                            <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
                        </g>
                    </g>
                </svg>
                <Box sx={{ mt: 1, color: currentTheme === 'light' ? '#000' : '#FFF' }}>No Rows</Box>
            </StyledGridOverlay>
        );
    }

    const initialState = {
        pagination: {
            paginationModel: {
                pageSize: 6,
            },
        },
        columns: {
            columnVisibilityModel: {
                phoneNumber: false,
                createdCards: false,
                cardLimit: false
            }
        },
    };

    return (<>
        {/*<Dialog*/}
        {/*    open={externalUserManagementModalState}*/}
        {/*    fullScreen*/}
        {/*    onClose={handleClose}*/}
        {/*    onBackdropClick={handleBackdropClick}*/}
        {/*    PaperProps={{ sx: { width: '90%', height: '90%', borderRadius: "0px", boxShadow: currentTheme === "light" ? '0px 4px 10px rgba(0, 0, 0, 0.5)' : '0px 4px 10px rgba(255, 255, 255, 0.5)' } }}*/}
        {/*    sx={{*/}
        {/*        backdropFilter: "blur(25px)",*/}
        {/*    }}*/}
        {/*>*/}
        {/*    <DialogTitle textAlign="center" sx={{ p: 3, fontSize: 16, lineHeight: '20px' }}>External User Accounts Management</DialogTitle>*/}
        {/*    <Box sx={{ height: '100%', width: '100%', padding: 5 }}>*/}
        {/*<CloseIcon onClick={handleClose} sx={{*/}
        {/*    cursor: 'pointer', fontSize: 32, padding: 0.5, background: '#FF0000', color: '#FFF', position: 'absolute', top: 0, right: 0,*/}
        {/*}} />*/}
        <DataGrid
            sx={{
                [`& .${gridClasses.row}`]: {
                    borderLeft: '2.5px solid #3C4DE7',
                    borderRadius: '0 10px 10px 0',
                    bgcolor: currentTheme === 'light' ? grey[200] : grey[900],
                    color: currentTheme === 'light' ? grey[900] : grey[200],
                },
                '& .MuiDataGrid-columnHeaders': {
                    border: 'none',
                    color: grey[600],
                    '&:hover': {
                        color: '#3C4DE7',
                    },
                },
                '& .MuiDataGrid-iconSeparator': {
                    display: 'none',
                },
                '& .MuiDataGrid-cell': {
                    border: 'none',
                },
                '& .MuiDataGrid-footerContainer ': {
                    border: 'none',
                },
                '& .MuiTablePagination-displayedRows': {
                    color: currentThemeColor,
                },
                '& .MuiButtonBase-root ': {
                    color: currentTheme === 'light' ? grey[900] : grey[200],
                },
                '& .MuiDataGrid-selectedRowCount': {
                    color: currentThemeColor
                },
                '& .MuiDataGrid-virtualScroller': {
                    color: currentTheme === 'light' ? grey[900] : grey[200],
                },
                '& .MuiDataGrid-virtualScroller::-webkit-scrollbar': {
                    width: '0.4em',
                },
                '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-track': {
                    background: currentTheme === 'light' ? 'rgba(0,0,0,0.1)' : 'rgba(255,255,255,0.1)',
                },
                '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb': {
                    borderRadius: "10px",
                    boxShadow: "inset 0 0 6px rgba(0,0,0,.3)",
                    backgroundColor: currentTheme === 'light' ? '#f5f5f5' : '#494848',
                },
                '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb:hover': {
                    background: currentTheme === 'light' ? '#494848' : '#f5f5f5',
                },
                border: 'none',
                '& .MuiDataGrid-cell.MuiDataGrid-cell--editing': {
                    backgroundColor: currentTheme === 'light' ? '#f4f1f1' : '#121331',
                },
            }}
            initialState={initialState}
            getRowSpacing={getRowSpacing}
            pageSizeOptions={[6]}
            // disableColumnMenu
            disableSorting
            disableSelectionOnClick
            rows={tableData}
            columns={columns}
            isCellEditable={isCellEditable}
            editMode="cell"
            processRowUpdate={processRowUpdate}
            onProcessRowUpdateError={handleProcessRowUpdateError}
            slots={{
                toolbar: QuickSearchToolbar,
                noRowsOverlay: CustomNoRowsOverlay,
                columnMenu: CustomColumnMenu
            }}
        />
        {/*</Box>*/}
        {/*</Dialog >*/}
    </>);
}

export default TransactionReport;