import React, { useEffect, useMemo } from 'react';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import { Box } from '@mui/system';
import {Autocomplete, TextField, Grid, ImageList, ImageListItem, IconButton} from '@mui/material';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LoadingButton } from '@mui/lab';
import { useForm } from 'react-hook-form';
import { useWindowWidth } from '../../../../utils/browser';
import PoiService from '../../../../services/api/poi.service';
import { setMessage } from '../../../../redux/slices/message';
import { setMessageSnackbarStateState } from '../../../../redux/slices/common';
import CrumbService from "../../../../services/api/crumb.service";
import Image from "mui-image";
import galleryAddBlack from "../../../../assets/images/icons/svg/gallery-add-black.svg";
import galleryAdd from "../../../../assets/images/icons/svg/gallery-add.svg";
import Compress from "compress.js";
import { getPermission } from "../../../../utils/cryptograpgy";
import ImageSelectionModal from "../image-selection-modal/ImageSelectionModal";
import ClearIcon from '@mui/icons-material/Clear';
import useTagInput from "../../../../hooks/useTagInput";

function RenameAudienceModal({ audience, audienceId, renameAudienceModalState, setRenameAudienceModalState, handleAudienceRename }) {
  const dispatch = useDispatch();
  const browserWidth = useWindowWidth();
  const { selectedCountry } = useSelector((state) => state.auth);
  const { user } = useSelector((state) => state.auth.auth);
  const [rename, setRename] = useState(audience.name);
  const { currentTheme } = useSelector((state) => state.theme);
  const [category, setCategory] = useState(
    audience.categories && Array.isArray(audience.categories)
      ? audience.categories.map(category => category)
      : []
  );
  const [crumbs, setCrumbs] = useState("");
  const [categoryList, setCategoryList] = useState("");
  const [selectedOptions, setSelectedOptions] = useState({ selectedCrumb: "" });
  const [selectedBrandType, setSelectedBrandType] = useState(null);
  const [selectedCrumbType, setSelectedCrumbType] = useState(null);
  const [selectedBrandTypeList, setSelectedBrandTypeList] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [imageSelectionModalState, setImageSelectionModalState] = useState(false);
  const [imageFileName, setImageFileName] = useState(null);
  const [imageFile, setImageFile] = useState(null);
  const { register, handleSubmit, reset, formState: { errors } } = useForm({
    mode: 'onChange'
  });
  const brandTypePermission = getPermission('brand_type');
  const categoriesPermission = getPermission('categories');
  const renameCardPermission = getPermission('card_name');
  const editCrumbCardPermission = getPermission('edit_crumb');
  const editImagePermission = getPermission('edit_card_image');
  const [initialImagePath, setInitialImagePath] = useState("");
  const [imageSelectionData, setImageSelectionData] = useState("");
  const [dataFromChild, setDataFromChild] = useState("");
  const [tags, setTags] = useState([]);

  useEffect(() => {

    setRename(audience.name);
    setImageFileName(audience.imageName);
    setSelectedBrandType(audience.brandType);
    setSelectedCrumbType(audience.crumbName);

    const audienceTagsArray = audience.tags && audience.tags.trim() !== ''
          ? audience.tags.split(',').map(tag => tag.trim())
          : [];

    setTags(audienceTagsArray);
    setInitialImagePath(audience.imagePath);
    setCategory(audience.categories && Array.isArray(audience.categories)
        ? audience.categories.map(category => category)
        : []);
      if (user && selectedCountry && renameAudienceModalState) {
        const queryParams = [
          `userId=${user.id}`,
          `countryId=${selectedCountry.id}`,
        ]
        PoiService.poiDetailsCategory(queryParams.join('&')).then(
          (data) => {
            if (data?.results?.length) {
              const filteredCat = data.results;
              setCategoryList(filteredCat);
            }
            return Promise.resolve();
          },
          (error) => {
            return Promise.reject();
          }
        )
        CrumbService.getCrumbs(queryParams.join('&')).then(
            (data) => {
              if (data?.results?.length) {
                // const crumbsList = data.results
                //     .filter(crumb => crumb.isDemoCrumb === null || crumb.isDemoCrumb === false)
                //     .reverse();
                // console.log("crumblist", crumbsList);
                setCrumbs(data.results);
              } else {
                setCrumbs([]);
              }
              return Promise.resolve();
            },
            (error) => {
              console.log(error);
              return Promise.reject();
            }
        );
        CrumbService.getBrands(queryParams.join('&')).then(
            (data) => {
              if (data?.results?.length) {
                setSelectedBrandTypeList(data.results);
              }
              return Promise.resolve();
            },
            (error) => {
              return Promise.reject();
            }
        )
      }
  }, [user, selectedCountry, renameAudienceModalState, audience])


    const {
        input,
        setInput,
        onKeyDown,
        onKeyUp,
        handleTagClick,
        handleBlur,
        onTagChange,
        isValidTag,
    } = useTagInput(tags, setTags, true); // Passing tags and setTags to the hook


    const initialCategory = useMemo(
    () =>
      audience.categories && Array.isArray(audience.categories)
        ? audience.categories.map(category => category)
        : [],
    [audience.categories]
  );

  const handleClose = () => {
    setImageSelectionData("");
    setCategory(initialCategory);
    setInitialImagePath("");
    setImageFile(null);
    setImageFileName(null);
    dispatch(setRenameAudienceModalState(false));
  };

  const handleKeyDown = (e) => {
    // console.log("values", event);
    if (e.key === 'Enter') {
      e.preventDefault();
      onSubmit();
    }
  };

    const onChangeOption = (newValue, type) => {
        setCategory(type);
    };

    const onChangeBrandType = (newValue, type) => {
    setSelectedBrandType(type);
  };

    const onChangeCrumb = (newValue, type) => {
        setSelectedCrumbType(type);
    };

  const onChangeSearchOption = (searchText) => {
      const queryParams = [
        `userId=${user.id}`,
        `countryId=${selectedCountry.id}`,
        `searchText=${searchText}`
      ]
      PoiService.poiDetailsCategory(queryParams.join('&')).then(
          (data) => {
            if (data?.results?.length) {
              const filteredCat = data.results;
              setCategoryList(filteredCat);
            }
            return Promise.resolve();
          },
          (error) => {
            return Promise.reject();
          }
      )
  };
  // const handleCategoryChange = (event, newValue) => {
  //
  //   const filteredData = category.filter(item => !newValue.some(newItem => newItem.id === item.id));
  //   setCategory(newValue);
  //
  //   setCategoryList((prevCat) =>
  //     prevCat.filter((option) => !newValue.some((cat) => cat.id === option.id))
  //   );
  //
  //   setCategoryList(prevCat => [...prevCat, ...filteredData]);
  // }

  const onSubmit = () => {
    // if (category.length === 0) {
    //   dispatch(setMessage({
    //     type: "error",
    //     title: "Select Atleast One Category",
    //   }));
    //   dispatch(setMessageSnackbarStateState(true));
    //   return;
    // }

    if(editCrumbCardPermission && (selectedCrumbType === null || selectedCrumbType === "")) {
      dispatch(setMessage({
        type: "error",
        title: "Select a crumb",
      }));
      dispatch(setMessageSnackbarStateState(true));
      return false;
    }

    if(!selectedBrandType){
      dispatch(setMessage({
        type: "error",
        title: "Select the nature of the brand",
      }));
      dispatch(setMessageSnackbarStateState(true));
      return false;
    }

    if (audience.categories && audience.name === rename) {

      const updatedAudience = {...audience};
      updatedAudience.categories = [];
      updatedAudience.categories = updatedAudience.categories.concat(category);
      audience = updatedAudience;
    }

      // if (editImagePermission && !imageFile) {
      //     // console.log("please upload an image")
      //     dispatch(setMessage({
      //         type: "error",
      //         title: "please upload an image"
      //     }));
      //     dispatch(setMessageSnackbarStateState(true));
      //     return false;
      // }
    handleAudienceRename(audience, audienceId, rename, category, setRenameAudienceModalState, renameAudienceModalState, selectedBrandType, selectedCrumbType, imageFile, imageSelectionData, tags);
    setImageFile(null);
    setImageFileName(null);
    setImageSelectionModalState(false);
    setImageSelectionData("");
    setInitialImagePath("");
  }

    const handleFileChange = (event) => {
        const file = event.target.files[0];

        if (file && (file.type === 'image/jpeg' || file.type === 'image/png')) {
            if (file.size > 2 * 1024 * 1024) {
                const compress = new Compress();
                compress.compress([file], {
                    size: 2, // Set the desired max size in MB
                    quality: 0.8, // Set the desired quality (0 to 1)
                    maxWidth: 800 // Set the desired max width in pixels
                }).then((resizedImages) => {
                    const resizedFileName = file.name;
                    const resizedFile = Compress.convertBase64ToFile(resizedImages[0].data, resizedFileName);
                    setImageFile(resizedFile);
                    setImageFileName(resizedFileName);
                }).catch((error) => {
                    console.error(error);
                    dispatch(setMessage({
                        type: "error",
                        title: "An error occurred while resizing the image",
                        description: error.message
                    }));
                    dispatch(setMessageSnackbarStateState(true));
                });
            } else {
                // Use the original image if it's already under 2 MB
                setImageFile(file);
                setImageFileName(file.name);
            }
        } else if (!file && imageFile) {
            setImageFile(imageFile);
            setImageFileName(imageFile.name);
        } else {
            // Handle non-image files
            dispatch(setMessage({
                type: "warning",
                title: "Please Upload jpeg or png Formats",
            }));
            dispatch(setMessageSnackbarStateState(true));
            setInitialImagePath(audience.imagePath);
        }
        setImageSelectionData("");
    };

    const handleImageSelectionChange = (event) => {
        setInitialImagePath("");
        setImageFileName(null);
        setImageFile(null);
        setImageSelectionModalState(true);
    };

    const clearSelectedImage = () => {
        setImageSelectionData("");
        setInitialImagePath(audience.imagePath);
    };

    function handleDataFromChild(data) {
        setDataFromChild(data);
        setImageSelectionData(data);
        setInitialImagePath(audience.imagePath);
    }


  return (<>
    <Dialog
      open={renameAudienceModalState}
      onClose={handleClose}
      // onBackdropClick={handleBackdropClick}
      PaperProps={{ sx: { width: "460px", borderRadius: "0px" } }}
      sx={{
        backdropFilter: "blur(25px)",
      }}
    >
      <DialogTitle textAlign="center" sx={{ p: 3, fontSize: 16, lineHeight: '20px' }}>Edit Audience</DialogTitle>
      <Box
        component='form'
        noValidate
        autoComplete='off'
        onSubmit={handleSubmit(onSubmit)}
      >
        <DialogContent sx={{ px: browserWidth > 500 ? 7.5 : 3 }}>
          <Box>
            { renameCardPermission && <TextField
              autoFocus
              InputProps={{
                style: { padding: "0 8px 10px 8px", fontSize: "16px" },
              }}
              InputLabelProps={{
                style: { color: "#6F7288" }
              }}
              id="audience_name"
              type="text"
              variant="standard"
              fullWidth
              sx={{ my: 1.5 }}
              value={rename}
              onChange={(e) => setRename(e.target.value)}
              onKeyDown={(e) => handleKeyDown(e)}
              placeholder="Audience Name"
            /> }

              { editCrumbCardPermission && <Autocomplete
                  fullWidth
                  options={crumbs || []}
                  defaultValue={ selectedCrumbType || null }
                  getOptionLabel={(option) => typeof option === 'object' ? (option.name || '') : option}
                  onChange={onChangeCrumb}
                  renderInput={(params) => (
                      <TextField
                          {...params}
                          placeholder={"Select a Crumb"}
                          variant="standard"
                          sx={{ my: 2, color: '#fff' }}
                          {...register("crumb")}
                          disabled={selectedCrumbType && selectedCrumbType.length === 0}
                          InputProps={{
                              ...params.InputProps,
                          }}
                      />
                  )}
                  paperprops={{
                      style: {
                          maxHeight: '50vh',
                          maxWidth: '50vh',
                          marginTop: '2px',
                          overflowX: 'auto',
                      },
                  }}
              /> }


            { categoriesPermission && <Autocomplete
              multiple
              fullWidth
              options={categoryList || []}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              getOptionLabel={(option) => option.name}
              defaultValue={category}
              onChange={onChangeOption}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder={category.length > 0
                    ? ""
                    : "Select a Category"}
                  variant="standard"
                  sx={{
                    my: 2,
                    '& .MuiChip-root': {
                      backgroundColor: currentTheme === 'light' ? '#ededed' : '#3f3e3e',
                      color: currentTheme === 'light' ? '#000' : '#fff'
                    },
                  }}
                  {...register("category")}
                  // disabled={category && category.length === 0}
                  onChange={(e) => {
                    const searchText = e.target.value;
                    // Do something with the search text, e.g., pass it to onChangeOption
                    onChangeSearchOption(searchText);
                  }}
                />
              )}
              paperprops={{
                style: {
                  maxHeight: '50vh',
                  maxWidth: '50vh',
                  marginTop: '2px',
                  overflowX: 'auto',
                },
              }}
            /> }

            { brandTypePermission && <Autocomplete
                fullWidth
                options={selectedBrandTypeList || []}
                defaultValue={selectedBrandType}
                getOptionLabel={(option) => option.name}
                onChange={onChangeBrandType}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        placeholder={"Define the nature of the brand"}
                        variant="standard"
                        sx={{ my: 2, color: '#fff' }}
                        {...register("brand")}
                        disabled={selectedBrandType && selectedBrandType.length === 0}
                        InputProps={{
                          ...params.InputProps,
                        }}
                    />
                )}
                paperprops={{
                  style: {
                    maxHeight: '50vh',
                    maxWidth: '50vh',
                    marginTop: '2px',
                    overflowX: 'auto',
                  },
                }}
            /> }
              {tags.map((tag) => {
                  // const formattedTags = validateCase(tag);
                  return (
                      <div
                          key={tag}
                          style={{
                              display: 'inline-flex',
                              alignItems: 'center',
                              margin: '7px 0',
                              marginRight: '10px',
                              padding: '0 10px',
                              paddingRight: '5px',
                              border: '1px solid orange',
                              borderRadius: '5px',
                              backgroundColor: 'orange',
                              whiteSpace: 'nowrap',
                              color: 'white',
                          }}
                      >
                          {tag}
                          <button
                              style={{
                                  display: 'flex',
                                  padding: '6px',
                                  border: 'none',
                                  backgroundColor: 'unset',
                                  cursor: 'pointer',
                                  color: 'white',
                              }}
                              onClick={() => handleTagClick(tag)}
                          >
                              x
                          </button>
                      </div>
                  );
              })}
              <TextField
                  InputProps={{
                      style: { padding: "0 8px 10px 8px" },
                  }}
                  InputLabelProps={{
                      style: { color: "#6F7288" }
                  }}
                  id="tags"
                  type="text"
                  variant="standard"
                  fullWidth
                  sx={{ my: 1.5 }}
                  value={input}
                  placeholder="Enter tags"
                  onKeyDown={onKeyDown}
                  onKeyUp={onKeyUp}
                  onChange={onTagChange}
                  onBlur={handleBlur}
              />
              {!isValidTag && input && <p style={{ color: 'red' }}>Special characters are not allowed</p>}
              {editImagePermission &&
                  <Grid container spacing={2}>
                          <Grid item xs={12} md={6}>
                              <Box className="UploadProfilePhoto"
                                   sx={{ position: 'relative', borderRadius: '0px', overflow: 'hidden', mb: 2 }}>
                                  <Box sx={{
                                      py: 2,
                                      height: '120px',
                                      display: 'flex',
                                      alignItems: 'center',
                                      justifyContent: 'center'
                                  }}>
                                      {imageFile ? (
                                          <img
                                              src={URL.createObjectURL(imageFile)}
                                              alt={imageFileName || 'Uploaded image'}
                                              //style={{ maxWidth: '100%', maxHeight: '100%' }}
                                          />
                                      ) : (<>
                                              {imageSelectionData ? (
                                                      <Box
                                                          component="img"
                                                          src={imageSelectionData}
                                                          alt="Selected"
                                                      /> ) : (

                                                  <Box
                                                      component="img"
                                                      src={initialImagePath}
                                                      alt="Selected"
                                                  />
                                              )}
                                                  {/*<IconButton*/}
                                                  {/*    onClick={clearSelectedImage}*/}
                                                  {/*    sx={{*/}
                                                  {/*        position: 'absolute',*/}
                                                  {/*        top: 8,*/}
                                                  {/*        right: 8,*/}
                                                  {/*        zIndex: 10,*/}
                                                  {/*        backgroundColor: 'rgba(0, 0, 0, 0.5)',*/}
                                                  {/*        color: 'white',*/}
                                                  {/*        '&:hover': {*/}
                                                  {/*            backgroundColor: 'rgba(0, 0, 0, 0.7)',*/}
                                                  {/*        },*/}
                                                  {/*    }}*/}
                                                  {/*>*/}
                                                  {/*    <ClearIcon />*/}
                                                  {/*</IconButton>*/}
                                          </>) }
                                  </Box>
                              </Box>
                          </Grid>
                      <Grid container item xs={12} md={6}>
                          <Grid item xs={12}>
                              <Box
                                  className="UploadProfilePhoto"
                                  sx={{ position: 'relative', borderRadius: '0px', overflow: 'hidden', mb: 2 }}
                              >
                                  <Box
                                      sx={{
                                          py: 2,
                                          height: '50px',
                                          display: 'flex',
                                          alignItems: 'center',
                                          justifyContent: 'center',
                                      }}
                                  >

                                      <Box sx={{mr: 2, width: 30, height: 30}}>
                                          <Image
                                              src={currentTheme === 'light' ? galleryAddBlack : galleryAdd}
                                              alt="Add image icon"
                                              layout="fill"
                                              objectFit="contain"
                                          />
                                      </Box>
                                      <Box>Upload an image</Box>

                                  </Box>
                                  <input
                                      style={{
                                          zIndex: 2,
                                          display: 'block',
                                          position: 'absolute',
                                          top: '50%',
                                          left: '50%',
                                          transform: 'translate(-50%, -50%)',
                                          width: '100%',
                                          height: '100%',
                                          opacity: 0,
                                          cursor: 'pointer',
                                      }}
                                      type="file"
                                      onChange={handleFileChange}
                                  />
                              </Box>
                          </Grid>

                          <Grid item xs={12}>
                              <Box
                                  className="UploadProfilePhoto"
                                  sx={{ position: 'relative', borderRadius: '0px', overflow: 'hidden', mb: 2 }}
                              >
                                  <Box
                                      sx={{
                                          py: 2,
                                          height: '50px',
                                          display: 'flex',
                                          alignItems: 'center',
                                          justifyContent: 'center',
                                      }}
                                  >
                                          <>
                                              <Box sx={{ mr: 2, width: 30, height: 30 }}>
                                                  <Image src={currentTheme === 'light' ? galleryAddBlack : galleryAdd} alt="Select" />
                                              </Box>
                                              <Box>select an image</Box>
                                          </>
                                  </Box>
                                  <input
                                      style={{
                                          zIndex: 2,
                                          display: 'block',
                                          position: 'absolute',
                                          top: '50%',
                                          left: '50%',
                                          transform: 'translate(-50%, -50%)',
                                          width: '100%',
                                          height: '100%',
                                          opacity: 0,
                                          cursor: 'pointer',
                                      }}
                                      onClick={handleImageSelectionChange}
                                  />
                              </Box>
                          </Grid>
                      </Grid>
                  </Grid>
              }
          </Box>
        </DialogContent>
        <Divider />
        <DialogActions sx={{ padding: "0px" }}>
          <ButtonGroup fullWidth size='large' aria-label="outlined primary button group">
            <Button variant='flat' onClick={handleClose} style={{ textTransform: "none", borderRadius: "0px" }}>Cancel</Button>
            <LoadingButton
              variant='contained'
              fullWidth
              type='submit'
              loading={isSubmitting}
              className="form-button"
              sx={{ textTransform: "none", borderRadius: "0px" }}
            >
              Ok
            </LoadingButton>
          </ButtonGroup>
        </DialogActions>
      </Box>
    </Dialog>

      {
          imageSelectionModalState &&
          <ImageSelectionModal
              imageSelectionModalState={imageSelectionModalState}
              setImageSelectionModalState={setImageSelectionModalState}
              setImageSelectionData={setImageSelectionData}
              sendDataToParent={handleDataFromChild}
          />
      }
  </>);
}

export default RenameAudienceModal;