import React, { useState } from 'react';
import { useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import { useDispatch, useSelector } from 'react-redux';
import { Autocomplete, Box, Chip, TextField } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useForm } from 'react-hook-form';
import { LoadingButton } from '@mui/lab';

import storeIcon from '../../../../assets/images/store.png';
import { setMessageSnackbarStateState, setCompareCardsModalState, setCompareStatusModalState } from '../../../../redux/slices/common';
import { setMessage } from '../../../../redux/slices/message';
import AudienceService from '../../../../services/api/audience.service';
import styles from '../../../common/card/audience/AudienceCard.module.scss';
import Image from 'mui-image';
import { Location, Profile2User } from 'iconsax-react';
import { setCompareStatusTableData } from "../../../../redux/slices/audience";

function CompareCardModal() {

  const { compareCardsModalState } = useSelector((state) => state.common);
  const { selectedCountry } = useSelector((state) => state.auth);
  const { user } = useSelector((state) => state.auth.auth);
  const { currentTheme } = useSelector((state) => state.theme);
  const dispatch = useDispatch();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [cards, setCards] = useState("");
  const [cardAData, setCardAData] = useState({
    imagePath: storeIcon,
    name: 'Main Card',
    crumbName: 'Crumb Name',
    id: '',
    totalAudienceCount: '0',
    totalLocationPin: '0'
  });
  const [cardBData, setCardBData] = useState({
    imagePath: storeIcon,
    name: 'Target Card',
    crumbName: 'Crumb Name',
    id: '',
    totalAudienceCount: '0',
    totalLocationPin: '0'
  });

  const { register, handleSubmit, reset, formState: { errors } } = useForm({
    mode: 'onChange'
  });

  const getAudience = async () => {
    try {
      if (selectedCountry) {
        const queryParams = [
          `userId=${user.id}`,
          `countryId=${selectedCountry.id}`,
        ];
        const data = await AudienceService.getAudience(queryParams.join('&'));
        if (data?.results?.length) {
          setCards(data.results);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (compareCardsModalState) {
      getAudience();
    }
  }, [compareCardsModalState, selectedCountry]);

  const compareDetails = async () => {
    try {
      if (user && selectedCountry) {
        const queryParams = [
          `userId=${user.id}`,
          `countryId=${selectedCountry.id}`,
        ];
        const data = await AudienceService.compareDetails(queryParams.join('&'));

        if (data?.results?.length) {
          dispatch(setCompareStatusTableData(data.results));
          //setTableData(data.results);
        } else {
          dispatch(setCompareStatusTableData([]));
          //setTableData([]);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onSubmit = () => {
    const queryParams = [
      `mainId=${cardAData.id}`,
      `compareId=${cardBData.id}`,
      `userId=${user.id}`,
      `countryId=${selectedCountry.id}`
    ]
    setIsSubmitting(true);
    AudienceService.compareAudience(queryParams.join('&')).then(
      (data) => {
        setIsSubmitting(false);
        dispatch(setMessage({
          type: "success",
          title: data?.message ? data.message : "success",
        }));
        dispatch(setMessageSnackbarStateState(true));
        setCardAData({
          imagePath: storeIcon,
          name: 'Main Card',
          crumbName: 'Crumb Name',
          id: '',
          totalAudienceCount: '0',
          totalLocationPin: '0'
        });
        setCardBData({
          imagePath: storeIcon,
          name: 'Target Card',
          crumbName: 'Crumb Name',
          id: '',
          totalAudienceCount: '0',
          totalLocationPin: '0'
        });
        dispatch(setCompareCardsModalState(false));
        compareDetails();
        //dispatch(setCompareStatusModalState(true));
      },
      (error) => {
        setIsSubmitting(false);
        dispatch(setMessage({
          type: "error",
          title: error.response.data.message,
        }));
        dispatch(setMessageSnackbarStateState(true));
      }
    )
  }

  const handleClose = (e, reason) => {
    if (reason !== "backdropClick") {
      setCardAData({
        imagePath: storeIcon,
        name: 'Main Card',
        crumbName: 'Crumb Name',
        id: '',
        totalAudienceCount: '0',
        totalLocationPin: '0'
      });
      setCardBData({
        imagePath: storeIcon,
        name: 'Target Card',
        crumbName: 'Crumb Name',
        id: '',
        totalAudienceCount: '0',
        totalLocationPin: '0'
      });
      dispatch(setCompareCardsModalState(false));
      dispatch(setCompareStatusModalState(true));
    }
  };

  const handleBackdropClick = (event) => {
    event.stopPropagation();
    return false;
  };

  return (<>
    <Dialog
      open={compareCardsModalState}
      fullScreen
      onClose={handleClose}
      onBackdropClick={handleBackdropClick}
      PaperProps={{ sx: { width: '90%', height: '90%', borderRadius: "0px", boxShadow: currentTheme === "light" ? '0px 4px 10px rgba(0, 0, 0, 0.5)' : '0px 4px 10px rgba(255, 255, 255, 0.5)' } }}
      sx={{
        backdropFilter: "blur(25px)",
      }}
    >
      <DialogTitle textAlign="center" sx={{ p: 3, fontSize: 22, lineHeight: '20px' }}>Cross Matching</DialogTitle>
      <Box
        component='form'
        noValidate
        autoComplete='off'
        onSubmit={handleSubmit(onSubmit)}
      >
        <Box sx={{ height: '100%', width: '100%', padding: 5 }}>
          <CloseIcon onClick={handleClose} sx={{
            cursor: 'pointer', fontSize: 32, padding: 0.5, background: '#FF0000', color: '#FFF', position: 'absolute', top: 0, right: 0,
          }} />

          <Box sx={{ display: 'flex', justifyContent: 'space-around' }}>
            <Autocomplete
              style={{ width: '400px' }}
              options={cards || []}
              // value={selectedOptions.selectedCategory || null}
              getOptionLabel={(option) => option.name}
              onChange={(event, newValue) => {
                if (newValue) {
                  setCardAData({
                    imagePath: newValue.imagePath,
                    name: newValue.name,
                    crumbName: newValue.crumbName,
                    id: newValue.id,
                    totalAudienceCount: newValue.totalAudienceCount,
                    totalLocationPin: newValue.totalLocationPin
                  });
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder={"Select a card"}
                  variant="standard"
                  sx={{ my: 2, color: '#fff' }}
                  {...register("cardNameA")}
                  disabled={cards && cards.length === 0}
                />
              )}
              PaperProps={{
                style: {
                  maxHeight: '50vh',
                  maxWidth: '50vh',
                  marginTop: '2px',
                  overflowX: 'auto',
                },
              }}
            />

            <Autocomplete
              style={{ width: '400px' }}
              options={cards || []}
              // value={selectedOptions.selectedCategory || null}
              getOptionLabel={(option) => option.name}
              onChange={(event, newValue) => {
                if (newValue) {
                  setCardBData({
                    imagePath: newValue.imagePath,
                    name: newValue.name,
                    crumbName: newValue.crumbName,
                    id: newValue.id,
                    totalAudienceCount: newValue.totalAudienceCount,
                    totalLocationPin: newValue.totalLocationPin
                  });
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder={"Select a card"}
                  variant="standard"
                  sx={{ my: 2, color: '#fff' }}
                  {...register("cardNameB")}
                  disabled={cards && cards.length === 0}
                />
              )}
              PaperProps={{
                style: {
                  maxHeight: '50vh',
                  maxWidth: '50vh',
                  marginTop: '2px',
                  overflowX: 'auto',
                },
              }}
            />
          </Box>

          <Box sx={{ display: 'flex', justifyContent: 'space-around', mt: 4 }}>
            <div style={{ maxWidth: '345px', width: '100%', display: 'flex', flexDirection: 'column' }}>
              <div style={{ flex: 1 }}>
                <Box className={`${styles.AudienceCard ?? ''} `} sx={{ position: 'relative', transition: '0.5s', boxShadow: '0px 0px 10px rgba(255, 255, 255, 0.1)' }} >
                  <Box className={`${styles.CrumbContent ?? ''}`} sx={{ position: 'relative', zIndex: 2 }}>
                    <Box className={`${styles.CrumbImage ?? ''}`} sx={{ position: 'relative', overflow: 'hidden', borderRadius: '12px', cursor: 'pointer' }} >
                      {/* <Image src={audience.imagePath} alt={audience.name} width="auto" height="auto" /> */}
                      <Image src={cardAData.imagePath} />
                    </Box>

                    <Box className={`${styles.CrumbCategoryDetails ?? ''}`} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', position: 'absolute', zIndex: 4, width: '100%', top: 16, left: 0 }}>
                      <Box className={`${styles.CrumbCategoryName ?? ''}`} sx={{ display: 'flex', alignItems: 'center', height: 32, borderRadius: '100px', fontSize: 14, fontWeight: '500', lineHeight: '17px', pl: 2, pt: 2 }}>
                        <Chip title={cardAData.crumbName} label={cardAData.crumbName.length > 18 ? `${cardAData.crumbName.substring(0, 18)}...` : cardAData.crumbName} component="h1" variant="contained" color="success" />
                      </Box>
                    </Box>

                    <Box className={`${styles.CrumbDetails ?? ''}`} sx={{ display: 'flex', flexDirection: 'column', position: 'absolute', zIndex: 6, width: 'calc(100% - 24px)', bottom: -16, left: '12px', borderRadius: '12px', px: 3, py: 2, overflow: 'hidden', boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)' }}>
                      <Box className={`${styles.CrumbNameLogo ?? ''}`} sx={{ display: 'flex', alignItems: 'center', mb: 2 }} >
                        <Box className={`${styles.CrumbLogo ?? ''}`} sx={{ overflow: 'hidden', borderRadius: '50%', width: 36, height: 36, background: '#eee', p: 1 }}>
                          <Image src={storeIcon} alt={'audience.name'} style={{ height: 28 }} />
                        </Box>
                        < Box title={cardAData.name} className={`${styles.CrumbName ?? ''}`} sx={{ ml: 2, fontSize: 20, fontWeight: '700', lineHeight: '24px' }}>
                          {cardAData.name}
                        </Box>
                      </Box>

                      <Box className={`${styles.CrumbVisitor ?? ''}`} sx={{ display: 'flex', alignItems: 'center' }} >
                        <Box className={`${styles.CrumbVisitorIcon ?? ''}`} sx={{ display: 'flex', mr: 2, fontSize: 12 }}>
                          <Profile2User size="16" />
                        </Box>
                        <Box className={`${styles.CrumbVisitorCount ?? ''}`} sx={{ display: 'flex', alignItems: 'center', fontSize: 12, lineHeight: '15px' }}>{cardAData.totalAudienceCount}</Box>
                      </Box>

                      <Box className={`${styles.CrumbLocation ?? ''}`} sx={{ display: 'flex', alignItems: 'center', mt: 2 }} >
                        <Box className={`${styles.CrumbLocationIcon ?? ''}`} sx={{ display: 'flex', mr: 2 }}>
                          <Location size="16" />
                        </Box>
                        <Box className={`${styles.CrumbLocationName ?? ''}`} sx={{ display: 'flex', alignItems: 'center', fontSize: 12, lineHeight: '15px' }}>{cardAData.totalLocationPin}</Box>
                      </Box>
                    </Box>
                  </Box>
                </Box >
              </div>
            </div>

            <div style={{ maxWidth: '345px', width: '100%', display: 'flex', flexDirection: 'column', mt: 4 }}>
              <div style={{ flex: 1 }}>
                <Box className={`${styles.AudienceCard ?? ''} `} sx={{ position: 'relative', transition: '0.5s', boxShadow: '0px 0px 10px rgba(255, 255, 255, 0.1)' }} >
                  <Box className={`${styles.CrumbContent ?? ''}`} sx={{ position: 'relative', zIndex: 2 }}>
                    <Box className={`${styles.CrumbImage ?? ''}`} sx={{ position: 'relative', overflow: 'hidden', borderRadius: '12px', cursor: 'pointer' }} >
                      {/* <Image src={audience.imagePath} alt={audience.name} width="auto" height="auto" /> */}
                      <Image src={cardBData.imagePath} />
                    </Box>

                    <Box className={`${styles.CrumbCategoryDetails ?? ''}`} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', position: 'absolute', zIndex: 4, width: '100%', top: 16, left: 0 }}>
                      <Box className={`${styles.CrumbCategoryName ?? ''}`} sx={{ display: 'flex', alignItems: 'center', height: 32, borderRadius: '100px', fontSize: 14, fontWeight: '500', lineHeight: '17px', pl: 2, pt: 2 }}>
                        <Chip title={cardBData.crumbName} label={cardBData.crumbName.length > 18 ? `${cardBData.crumbName.substring(0, 18)}...` : cardBData.crumbName} component="h1" variant="contained" color="success" />
                      </Box>
                    </Box>

                    <Box className={`${styles.CrumbDetails ?? ''}`} sx={{ display: 'flex', flexDirection: 'column', position: 'absolute', zIndex: 6, width: 'calc(100% - 24px)', bottom: -16, left: '12px', borderRadius: '12px', px: 3, py: 2, overflow: 'hidden', boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)' }}>
                      <Box className={`${styles.CrumbNameLogo ?? ''}`} sx={{ display: 'flex', alignItems: 'center', mb: 2 }} >
                        <Box className={`${styles.CrumbLogo ?? ''}`} sx={{ overflow: 'hidden', borderRadius: '50%', width: 36, height: 36, background: '#eee', p: 1 }}>
                          <Image src={storeIcon} alt={'audience.name'} style={{ height: 28 }} />
                        </Box>
                        < Box title={cardBData.name} className={`${styles.CrumbName ?? ''}`} sx={{ ml: 2, fontSize: 20, fontWeight: '700', lineHeight: '24px' }}>
                          {cardBData.name}
                        </Box>
                      </Box>

                      <Box className={`${styles.CrumbVisitor ?? ''}`} sx={{ display: 'flex', alignItems: 'center' }} >
                        <Box className={`${styles.CrumbVisitorIcon ?? ''}`} sx={{ display: 'flex', mr: 2, fontSize: 12 }}>
                          <Profile2User size="16" />
                        </Box>
                        <Box className={`${styles.CrumbVisitorCount ?? ''}`} sx={{ display: 'flex', alignItems: 'center', fontSize: 12, lineHeight: '15px' }}>{cardBData.totalAudienceCount}</Box>
                      </Box>

                      <Box className={`${styles.CrumbLocation ?? ''}`} sx={{ display: 'flex', alignItems: 'center', mt: 2 }} >
                        <Box className={`${styles.CrumbLocationIcon ?? ''}`} sx={{ display: 'flex', mr: 2 }}>
                          <Location size="16" />
                        </Box>
                        <Box className={`${styles.CrumbLocationName ?? ''}`} sx={{ display: 'flex', alignItems: 'center', fontSize: 12, lineHeight: '15px' }}>{cardBData.totalLocationPin}</Box>
                      </Box>
                    </Box>
                  </Box>
                </Box >
              </div>
            </div>
          </Box>


          <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '80px' }}>
            <LoadingButton
              variant='contained'
              // fullWidth
              type='submit'
              loading={isSubmitting}
              className="form-button"
              sx={{ textTransform: "none", borderRadius: "0px" }}
            >
              Generate Cross Matching
            </LoadingButton>
          </Box>
        </Box>
      </Box>
    </Dialog >
  </>);
}

export default CompareCardModal;