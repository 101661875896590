import React from 'react';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import { Box } from '@mui/system';
import { TextField } from '@mui/material';
import { useState } from 'react';
import { LoadingButton } from '@mui/lab';
import { useWindowWidth } from '../../../../utils/browser';
import { useForm } from 'react-hook-form';


function AssignAudienceConfirmModal({ audience, assignAudienceModalState, setAssignAudienceModalState, handleAudienceAssign }) {
  const [tags, setTags] = useState([]);
  const [isKeyReleased, setIsKeyReleased] = useState(false);
  const [input, setInput] = useState('');
  const browserWidth = useWindowWidth();
  // const [isGmail, setIsGmail] = React.useState(false);

  const { register, handleSubmit, reset, formState: { errors } } = useForm({
    mode: 'onChange'
  });

  const handleClose = () => {
    setAssignAudienceModalState(false);
  };

  const onSubmit = () => {
    // console.log(audience)
    handleAudienceAssign(audience, tags, setAssignAudienceModalState, setTags, input, setInput);
    // setAssignAudienceModalState(false);
  };

  // const validateGmail = (text) => {
  //   const gmailRegex = /^[a-zA-Z0-9._%+-]+@memob.com$/;
  //   return gmailRegex.test(text);
  // };

  const onChange = (e) => {
    const { value } = e.target;
    setInput(value);
    // setIsGmail(validateGmail(value));
  };

  const onKeyDown = (e) => {
    const { key } = e;
    const trimmedInput = input.trim();
    // if (isGmail) {
    if (key === ',' || key === ' ') {
      if (trimmedInput.length && !tags.includes(trimmedInput)) {
        e.preventDefault();
        setTags((prevState) => [...prevState, trimmedInput]);
      }
      setInput('');
    } else if (key === 'Backspace' && !input.length && tags.length && isKeyReleased) {
      const tagsCopy = [...tags];
      const poppedTag = tagsCopy.pop();
      e.preventDefault();
      setTags(tagsCopy);
      setInput(poppedTag);
    } else if (key === 'Enter') {
      e.preventDefault();
      onSubmit();
    }
    // }
    setIsKeyReleased(false);
  };

  const onKeyUp = () => {
    setIsKeyReleased(true);
  }

  const handleTagClick = (tag) => {
    const updatedTags = tags.filter((t) => t !== tag);
    setTags(updatedTags);
  };

  const handleBlur = () => {
    if (input.trim().length > 0 && !tags.includes(input.trim())) {
      setTags((prevState) => [...prevState, input.trim()]);
    }
    setInput('');
  };

  return (<>
    <Dialog
      open={assignAudienceModalState}
      onClose={handleClose}
      PaperProps={{ sx: { width: "390px", borderRadius: "0px" } }}
      sx={{
        backdropFilter: "blur(25px)",
      }}
    >
      <DialogTitle sx={{ p: 3, textAlign: "center", fontWeight: "bold", fontSize: "25px" }} >
        {"Assign to"}
      </DialogTitle>
      <Box
        component='form'
        noValidate
        autoComplete='off'
        onSubmit={handleSubmit(onSubmit)}
      >
        <DialogContent sx={{ px: browserWidth > 500 ? 7.5 : 3 }}>
          <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
            {tags.map((tag) => (
              <span
                key={tag}
                style={{
                  display: 'inline-flex',
                  alignItems: 'center',
                  margin: '7px 0',
                  marginRight: '10px',
                  padding: '0 10px',
                  paddingRight: '5px',
                  border: '1px solid orange',
                  borderRadius: '5px',
                  backgroundColor: 'orange',
                  whiteSpace: 'nowrap',
                  color: 'white'
                }}
              >
                {tag}
                <button
                  style={{
                    display: 'flex',
                    padding: '6px',
                    border: 'none',
                    backgroundColor: 'unset',
                    cursor: 'pointer',
                    color: 'white'
                  }}
                  onClick={() => handleTagClick(tag)}
                >
                  x
                </button>
              </span>
            ))}
          </Box>
          <Box>
            <TextField
              autoFocus
              // multiline
              // rows={4}
              InputProps={{
                style: { padding: "0 8px 10px 8px", fontSize: "16px" },
              }}
              InputLabelProps={{
                style: { color: "#6F7288" }
              }}
              id="tags"
              type="text"
              variant="standard"
              fullWidth
              sx={{ my: 1.5 }}
              value={input}
              placeholder="Email"
              onKeyDown={onKeyDown}
              onKeyUp={onKeyUp}
              onChange={onChange}
              onBlur={handleBlur}
            />
            {/* {isGmail && <p style={{ color: 'green' }}>Valid Gmail address entered.</p>}
            {!isGmail && input && <p style={{ color: 'red' }} >Invalid Gmail address.</p>} */}
          </Box>

        </DialogContent>
      </Box>
      <Divider />
      <DialogActions sx={{ padding: "0px" }}>
        <ButtonGroup fullWidth size='large' aria-label="outlined primary button group">
          <Button variant='flat' onClick={handleClose} style={{ textTransform: "none", borderRadius: "0px" }}>Cancel</Button>
          <LoadingButton className="form-button" variant='contained' onClick={() => onSubmit()} sx={{ textTransform: "none", borderRadius: "0px" }}>Ok</LoadingButton>
        </ButtonGroup>
      </DialogActions>
    </Dialog>
  </>);
}

export default AssignAudienceConfirmModal;