import { authApi } from "../interceptor/auth.interceptor";

class UserService {

  static async getProfile() {
    return await authApi
      .get("user/details")
      .then((response) => {
        return response?.data;
      });
  }

  static async updateProfile(id, queryParams, formData) {
    return await authApi
      .put(`user/details/${id}?${queryParams}`, formData)
      .then((response) => {
        // console.log(response);
        return response?.data;
      });
  }

  static async changePassword(formData) {
    return await authApi
      .put(`user/change-password/`, formData)
      .then((response) => {
        // console.log(response);
        return response?.data;
      });
  }

    static async getPoiPincolor() {
        return await authApi
            .get("poi/pin-colors")
            .then((response) => {
                return response?.data;
            });
    }

    static async externalUserCall() {
        return await authApi
            .get(`user/external-users`)
            .then((response) => {
                return response?.data;
            });
    }

    static async externalUserTierDetailsCall() {
        return await authApi
            .get(`user/external-users-tiers`)
            .then((response) => {
                return response?.data;
            });
    }

    static async updateExternalUserDetails(queryParams) {
        return await authApi
            .put(`user/external?${queryParams}`)
            .then((response) => {
                // console.log(response);
                return response?.data;
            });
    }

    static async getAllClientsDetailsCall(queryParams) {
        return await authApi
            .get(`user/all-clients?${queryParams}`)
            .then((response) => {
                return response?.data;
            });
    }

    static async contactUs(formData) {
        return await authApi
            .post(`user/contact-us/`, formData)
            .then((response) => {
                // console.log(response);
                return response?.data;
            });
    }

    static async updateCountries(queryParams) {
        return await authApi
            .post(`user/update-countries?${queryParams}`)
            .then((response) => {
                // console.log(response);
                return response?.data;
            });
    }
}

export default UserService;