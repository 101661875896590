import React, { useState } from 'react';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import { Button, Link, Menu, MenuItem, Switch, Tooltip, ClickAwayListener } from '@mui/material';
import { tooltipClasses } from '@mui/material/Tooltip';
import Image from 'mui-image';
import styles from './MenuSideBar.module.scss';
import { styled } from '@mui/material/styles';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import BoltIcon from '@mui/icons-material/Bolt';
import CheckIcon from '@mui/icons-material/Check';
import AddIcon from '@mui/icons-material/Add';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import { connect } from 'react-redux';
import { useCookies } from "react-cookie";
import { useLocation, useNavigate } from 'react-router-dom';
import { TextField } from '@mui/material';
import { useDispatch } from 'react-redux';

import {
  setCreateCrumbModalState,
  setProfileSideBarState,
  setAudienceActivityModalState,
  setFilterPoiModalState,
  setUserManagementModalState,
  setMessageSnackbarStateState,
  setCompareCardsModalState,
  setCompareStatusModalState,
  setExternalUserManagementModalState
} from '../../../../redux/slices/common';
import AuthService from '../../../../services/api/auth.service';
import { accessToken, appName, baseUrl, refreshToken } from '../../../../config/config';
import { logout, setSelectedCountry, setUser } from '../../../../redux/slices/auth';

import defaultUser from "../../../../assets/images/user.png";
import logo from '../../../../assets/images/logo.svg';
import logoBlack from '../../../../assets/images/logo-black.svg';
import mediatikslogo from "../../../../assets/images/Mediatiks-logo-white.svg";
import mediatikslogoBlack from "../../../../assets/images/Mediatiks-logo-black.svg";
import {
  Heart,
  Notepad2,
  SearchNormal1,
  Trash,
  Flash,
  Location,
  Grid1,
  GridEdit,
  Cards,
  UserTick,
  Routing,
  User
} from 'iconsax-react';
import TableRowsIcon from '@mui/icons-material/TableRows';
import { defaultCountries } from '../../../../config/countries.config';
import { setCrumbs } from '../../../../redux/slices/crumb';
import {
  setAudiences,
  setApprovalAudiences,
  setTotalSelectedAudience,
  setCampaignAudiences
} from '../../../../redux/slices/audience';
import { getPermission } from '../../../../utils/cryptograpgy';
import { setCurrentTheme } from '../../../../redux/slices/theme';
import AudienceService from '../../../../services/api/audience.service';
import { setMessage } from '../../../../redux/slices/message';
import CrumbService from '../../../../services/api/crumb.service';

const LightTooltip = styled(({ className, currentTheme, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme, currentTheme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: currentTheme === 'light' ? '#010412' : theme.palette.common.white,
    color: currentTheme === 'light' ? '#fff' : '#3C4DE7',
    boxShadow: theme.shadows[1],
    fontSize: 12,
  },
  [`& .${tooltipClasses.tooltip} .MuiTooltip-arrow`]: {
    color: currentTheme === 'light' ? '#010412' : theme.palette.common.white,
  },
}));

const MaterialUISwitch = styled(({ currentTheme, ...props }) => (
  <Switch {...props} />
))(({ theme }) => ({
  width: 48,
  height: 36,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    margin: 4,
    padding: 0,
    transform: 'translateX(0px)',
    '&.Mui-checked': {
      color: '#0A1858',
      transform: 'translateX(12px)',
      '& .MuiSwitch-thumb': {
        backgroundColor: '#5561B5',
      },
      '& .MuiSwitch-thumb:before': {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
          '#0A1858',
        )}" d="M4.2 2.5l-.7 1.8-1.8.7 1.8.7.7 1.8.6-1.8L6.7 5l-1.9-.7-.6-1.8zm15 8.3a6.7 6.7 0 11-6.6-6.6 5.8 5.8 0 006.6 6.6z"/></svg>')`,
      },
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: '#151937',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    backgroundColor: '#FFD481',
    width: 28,
    height: 28,
    '&:before': {
      content: "''",
      position: 'absolute',
      width: '100%',
      height: '100%',
      left: 0,
      top: 0,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
        '#FFB11A',
      )}" d="M9.305 1.667V3.75h1.389V1.667h-1.39zm-4.707 1.95l-.982.982L5.09 6.072l.982-.982-1.473-1.473zm10.802 0L13.927 5.09l.982.982 1.473-1.473-.982-.982zM10 5.139a4.872 4.872 0 00-4.862 4.86A4.872 4.872 0 0010 14.862 4.872 4.872 0 0014.86 10 4.872 4.872 0 0010 5.139zm0 1.389A3.462 3.462 0 0113.471 10a3.462 3.462 0 01-3.473 3.472A3.462 3.462 0 016.527 10 3.462 3.462 0 0110 6.528zM1.665 9.305v1.39h2.083v-1.39H1.666zm14.583 0v1.39h2.084v-1.39h-2.084zM5.09 13.928L3.616 15.4l.982.982 1.473-1.473-.982-.982zm9.82 0l-.982.982 1.473 1.473.982-.982-1.473-1.473zM9.305 16.25v2.083h1.389V16.25h-1.39z"/></svg>')`,
    },
  },
  '& .MuiSwitch-track': {
    opacity: 1,
    backgroundColor: '#E6E6E6',
    borderRadius: 50,
  },
}));

function MenuSideBar({ user, selectedCountry, currentTheme, setCurrentTheme, setSelectedCountry, setCreateCrumbModalState, setAudienceActivityModalState, setUserManagementModalState, setExternalUserManagementModalState, setCompareCardsModalState, setCompareStatusModalState, setProfileSideBarState, setUser, logout, mobileMenuBarState }) {

  // console.log("user", user);

  const location = useLocation();
  const [searchText, setSearchText] = useState("");
  const [collapsed, setCollapsed] = useState(false);
  const [searchTextBoxOpen, setSearchTextBoxOpen] = React.useState(false);
  const crumbList = JSON.parse(localStorage.getItem('crumbList'));
  const audList = JSON.parse(localStorage.getItem('cards'));
  const approveClientList = JSON.parse(localStorage.getItem('approveClientList'));

  const addCrumbPermission = getPermission('add_crumb');
  const deleteCrumbPermission = getPermission('delete_crumb');
  const favouriteCardPermission = getPermission('like_card');
  const audienceActivityPermission = getPermission('audience_history');
  const filterPoiPermission = getPermission('filter_poi');
  const userManagementPermission = getPermission('user_management');
  const [menuOrder, setMenuOrder] = useState(1);
  const [selectedMenu, setSelectedMenu] = useState(false);
  const activityLogPermission = getPermission('activity_log');
  const homeButtonPermission = getPermission('home_button');
  const campaignsPermission = getPermission('campaigns');

  const backButtonUrl = localStorage.getItem('back-button-url');

  //const hostName = window.location.hostname;

  const audienceActivity = (value) => {
    const a = setAudienceActivityModalState
    dispatch(setAudienceActivityModalState(true));
    setMenuOrder(value)
  };

  // const poiFilter = (value) => {
  //   setMenuOrder(value)

  //   dispatch(setFilterPoiModalState(true));
  // };

  const userManagement = (value) => {
    dispatch(setUserManagementModalState(true));
    setMenuOrder(value)

  }

  const externalUserManagement = (value) => {
    dispatch(setExternalUserManagementModalState(true));
    setMenuOrder(value)
  }

  const compareCards = () => {
    dispatch(setCompareCardsModalState(true));
  }

  const compareStatus = () => {
    dispatch(setCompareStatusModalState(true));
  }

  const handleFavorite = (value) => {
    const queryParams = [
      `userId=${user.id}`,
      `countryId=${selectedCountry.id}`,
    ];
    AudienceService.getAudience(queryParams.join('&'))
      .then((data) => {
        console.log('from menuside bar')
        const audienceList = data.results.reverse();
        const audienceFilteredList = audienceList.filter(x => (x.isLike === true));
        if (audienceFilteredList?.length > 0) {
          setMenuOrder(value)
          navigate('/favourite-cards');

        } else {
          setMenuOrder(1)

          dispatch(setMessage({
            type: "error",
            title: "No Favorite Card",
          }));
          dispatch(setMessageSnackbarStateState(true));
        }
      }
      )
      .catch((error) => {
        console.log(error);
      });
  }

  const handleDeletedCrumbs = (value) => {
    setMenuOrder(value)
    const queryParams = [
      `userId=${user.id}`,
      `countryId=${selectedCountry.id}`,
      `status=Deactivated`
    ]
    CrumbService.getDeletedCrumb(queryParams.join('&')).then(
      (data) => {
        if (data?.results?.length) {

          navigate('/deleted-crumbs')


        } else {
          dispatch(setMessage({
            type: "error",
            // title: data?.message ? data.message : "Audience restored successfully",
            title: "No Deleted Crumbs",
          }));
          setMenuOrder(1)
          dispatch(setMessageSnackbarStateState(true));
        }
        return Promise.resolve();
      },
      (error) => {
        console.log(error);
        return Promise.reject();
      }
    );
  }

  const sidebarTopMenu = [
    {
      title: "Crumbs",
      icon: Notepad2,
      active: false,
      tooltip: true,
      permission: homeButtonPermission,
      link: '/home',
      menuOrder: 1,
    },
    {
      title: (
        <>
          <TextField
            autoFocus
            variant="outlined"
            size="small"
            // placeholder={audList ? 'Card Search' : 'Crumb Search'}
            placeholder='Filter'
            type="search"
            value={searchText}
            onChange={(e) => handleSearchChange(e)}
            sx={{
              "& fieldset": { border: 'none' },
              "& input": { color: currentTheme === 'light' ? '#fff' : '#3C4DE7' }
            }} />
        </>
      ),
      icon: SearchNormal1,
      active: false,
      tooltip: true,
      permission: true,
      tooltipHtml: true,
      menuOrder: 2,
      // link: '/',
    },
    {
      title: 'Favorites',
      icon: Heart,
      active: false,
      tooltip: true,
      permission: favouriteCardPermission,
      menuOrder: 3,
      // link: '/favourite-cards',
      click: () => handleFavorite(3),

    },
    {
      title: 'Deleted Crumbs',
      icon: Trash,
      active: false,
      tooltip: true,
      permission: deleteCrumbPermission,
      menuOrder: 4,
      // link: '/deleted-crumbs',
      click: () => handleDeletedCrumbs(4),
    },
    {
      title: 'Activity Log',
      icon: PendingActionsIcon,
      active: false,
      tooltip: true,
      permission: activityLogPermission,
      menuOrder: 5,
      link: '/activity',
    },
    {
      title: 'Activate Campaign',
      icon: BoltIcon,
      active: false,
      tooltip: true,
      permission: campaignsPermission,
      menuOrder: 6,
      link: '/campaigns',
      // click: () => compareCards(5),
    }
  ];

  const subMenu = [
    // {
    //   title: 'Audience Activation History',
    //   icon: Flash,
    //   active: false,
    //   tooltip: true,
    //   permission: audienceActivityPermission,
    //   menuOrder: 6,
    //
    //   // link: '/audience-history',
    //   click: () => audienceActivity(6),
    // },
    // {
    //   title: 'System Locations Filter',
    //   icon: Location,
    //   active: false,
    //   tooltip: true,
    //   permission: filterPoiPermission,
    //   menuOrder: 7,
    //   // link: '/audience-history',
    //   click: () => poiFilter(7),
    // },
    // {
    //   title: 'Approve Demo Users',
    //   icon: UserTick,
    //   active: false,
    //   tooltip: true,
    //   permission: userManagementPermission,
    //   menuOrder: 8,
    //   // link: '/audience-history',
    //   click: () => userManagement(8),
    // },
    // {
    //   title: 'External Users Details',
    //   icon: User,
    //   active: false,
    //   tooltip: true,
    //   permission: externalUserManagementPermission,
    //   menuOrder: 9,
    //   // link: '/audience-history',
    //   click: () => externalUserManagement(9),
    // },
  ]

  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const menuOpen = Boolean(menuAnchorEl);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // eslint-disable-next-line no-unused-vars
  const [cookies, setCookie, removeCookie] = useCookies();

  const handleSearchTextBoxClose = () => {
    setSearchTextBoxOpen(false);
  };

  const handleSearchTextBoxOpen = () => {
    setSearchTextBoxOpen(true);
  };

  const handleSearchChange = (event) => {

    const searchText = event.target.value.toLowerCase();

    if (approveClientList?.length) {
      const filteredAudList = approveClientList.filter((aud) =>
          aud.name.toLowerCase().includes(searchText)
      );
      dispatch(setApprovalAudiences(filteredAudList));
    }
    // Deleted crumb list search needs to be add
    if (crumbList?.length && !audList) {
      const filtered = crumbList.filter((crumb) =>
        crumb.name.toLowerCase().includes(searchText)
      );
      dispatch(setCrumbs(filtered));
    }

    if (crumbList?.length && audList?.length) {
      const filteredAudList = audList.filter((aud) =>
        aud.name.toLowerCase().includes(searchText)
      );
      dispatch(setAudiences(filteredAudList));
    }

    setSearchText(event.target.value);
  };


  const handleMenuOpen = (event) => {
    setMenuAnchorEl(event.currentTarget);
  };

  const handleMoreOption = () => {
    setCollapsed((prevCollapsed) => !prevCollapsed);
    setSelectedMenu(!selectedMenu)
  }

  const handleMenuClose = () => {
    setMenuAnchorEl(null);
  };

  const handleAddCrumb = () => {
    if (addCrumbPermission) {
      setCreateCrumbModalState(true);
    }
  };

  const handleProfileSideBar = () => {

    if(user.isExternalUser || user.isInternalUser) {

      navigate('/user-profile');
    } else {

      setProfileSideBarState(true);
    }
  };

  const handleTopMenu = (menu) => {
    navigate(menu.link);
    setMenuOrder(menu.menuOrder)
    dispatch(setTotalSelectedAudience(0));
    dispatch(setCampaignAudiences([]));
  };

  const handleCountry = (country) => {
    // console.log(country);
    if (country) {
      const countryFromDefaulCountries = defaultCountries.find(c => c.name === country.name);
      const selectedCountryDummy = {
        ...country,
        ...countryFromDefaulCountries
      };
      setSelectedCountry(selectedCountryDummy);

      if (backButtonUrl === '/approve') {
        navigate('/approve');
      } else {
        navigate('/home');
      }

    }
  };

  const handleLogout = () => {
    // console.log("handleLogout");
    localStorage.setItem('clickEvent', 'logout');
    AuthService.logout().then(
      (data) => {
        logoutCookie();
        return Promise.resolve();
      },
      (error) => {
        logoutCookie();
      }
    );
  };

  const logoutCookie = () => {
    removeCookie(accessToken, { path: '/' });
    removeCookie(refreshToken, { path: '/' });
    setUser(null);
    logout();
  }

  const handleThemeChanged = (e) => {
    const isCurrentDark = currentTheme === 'dark';
    setCurrentTheme(isCurrentDark ? 'light' : 'dark');
    localStorage.setItem('currentTheme', isCurrentDark ? 'light' : 'dark');
  }

  return (
    <Box className={`${styles['theme-' + currentTheme]} `}>
      <Box className={`${styles.SidebarArea ?? ''} ${mobileMenuBarState ? styles.MobileMenuBarActive : ''}`} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center', overflowY: 'auto', height: '100vh', textAlign: 'center', position: 'fixed', top: 0, left: 0, transition: '.3s' }}>
        <Box className={`${styles.SidebarTopArea ?? ''}`} sx={{ px: 0, pt: 7 }}>
          <Box className={`${styles.SidebarLogo ?? ''}`} sx={{ mb: 4 }}>
            <Link href={backButtonUrl === '/approve' ? '/approve' : '/home'}>
              {/*{hostName === 'map.mediatiks.com' ?*/}
              {/*    <Image src={currentTheme === 'light' ? mediatikslogoBlack : mediatikslogo} alt={appName} /> :*/}
                <Image src={currentTheme === 'light' ? logoBlack : logo} alt={appName} />
              {/*}*/}
            </Link>
          </Box>
          <List className={`${styles.List ?? ''}`} sx={{ p: 0, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            {sidebarTopMenu?.length > 0 && sidebarTopMenu.map((menu, index) => {
              const Icon = menu.icon;
              if (menu.permission) {
                return (
                  <ListItem className={`${styles.ListItem ?? ''}`} sx={{ p: 0, mb: 2, mx: 'auto', textAlign: 'center', width: 'auto' }} key={index}>
                    <ListItemAvatar className={`${styles.ListItemAvatar ?? ''}`} sx={{ m: 0, cursor: 'pointer', minWidth: 'initial' }} onClick={menu.click ? menu.click : () => handleTopMenu(menu)}>
                      {menu.tooltipHtml ?
                        <ClickAwayListener onClickAway={handleSearchTextBoxClose}>
                          <div>
                            <LightTooltip
                              title={menu.title}
                              arrow
                              placement="right"
                              className={`${styles.ListItemTooltip ?? ''}`}
                              sx={{ m: 0 }}
                              onClose={handleSearchTextBoxClose}
                              open={searchTextBoxOpen}
                              currentTheme={currentTheme}
                              disableFocusListener
                              disableHoverListener
                              disableTouchListener
                            >
                              <Avatar className={`${styles.Avatar ?? ''} ${searchTextBoxOpen ? styles.Active : ''}`} sx={{ m: 0, width: 45, height: 45 }} onClick={handleSearchTextBoxOpen}>
                                <Icon className={`${styles.Icon ?? ''}`} />
                              </Avatar>
                            </LightTooltip>
                          </div>
                        </ClickAwayListener>
                        :
                        <LightTooltip title={menu.title} arrow placement="right" className={`${styles.ListItemTooltip ?? ''}`} sx={{ m: 0 }} currentTheme={currentTheme}>
                          <Avatar className={`${styles.Avatar ?? ''} ${!selectedMenu && menuOrder === menu.menuOrder ? styles.Active : ''}`} sx={{ m: 0, width: 45, height: 45 }}>
                            <Icon className={`${styles.Icon ?? ''}`} />
                          </Avatar>
                        </LightTooltip>
                      }
                    </ListItemAvatar>
                  </ListItem>
                )
              } else {
                return null;
              }
            })}
            {/*<ListItem>*/}
            {/*  {subMenuPermission &&*/}
            {/*    <ListItemAvatar className={`${styles.ListItemAvatar ?? ''}`} sx={{ mb: 1, cursor: 'pointer', minWidth: 'initial', position: 'relative', zIndex: '1' }} onClick={handleMoreOption}>*/}
            {/*      <LightTooltip title={!collapsed ? 'More Options' : 'Less Options'} arrow placement="right" className={`${styles.ListItemTooltip ?? ''}`} sx={{ m: 0 }} currentTheme={currentTheme} >*/}
            {/*        <Avatar className={`${styles.Avatar ?? ''} ${selectedMenu ? styles.Active : ''}`} sx={{ m: 0, width: 45, height: 45 }} >*/}
            {/*          <TableRowsIcon />*/}
            {/*          /!* {collapsed ?*/}
            {/*          <ArrowUp2 />*/}
            {/*          :*/}
            {/*          <ArrowDown2 />*/}
            {/*        } *!/*/}
            {/*        </Avatar>*/}
            {/*      </LightTooltip>*/}
            {/*    </ListItemAvatar>*/}
            {/*  }*/}
            {/*</ListItem>*/}
          </List>
          {/* *********************************************************************************************************** */}
          {/*{collapsed &&*/}
          {/*  <List className={`${styles.List ?? ''}`} sx={{ p: 0, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>*/}
          {/*    {subMenu?.length > 0 && subMenu.map((menu, index) => {*/}
          {/*      const Icon = menu.icon;*/}
          {/*      if (menu.permission) {*/}
          {/*        return (*/}
          {/*          <ListItem className={`${styles.ListItem ?? ''}`} sx={{ p: 0, mb: 2, mx: 'auto', textAlign: 'center', width: 'auto' }} key={index}>*/}
          {/*            <ListItemAvatar className={`${styles.ListItemAvatar ?? ''}`} sx={{ m: 0, cursor: 'pointer', minWidth: 'initial' }} onClick={menu.click ? menu.click : () => handleTopMenu(menu)}>*/}
          {/*              <LightTooltip title={menu.title} arrow placement="right" className={`${styles.ListItemTooltip ?? ''}`} sx={{ m: 0 }} currentTheme={currentTheme}>*/}
          {/*                <Avatar className={`${styles.Avatar ?? ''} ${!selectedMenu && menuOrder === menu.menuOrder ? styles.Active : ''}`} sx={{ m: 0, width: 45, height: 45 }}>*/}
          {/*                  <Icon className={`${styles.Icon ?? ''}`} />*/}
          {/*                </Avatar>*/}
          {/*              </LightTooltip>*/}
          {/*            </ListItemAvatar>*/}
          {/*          </ListItem>*/}
          {/*        )*/}
          {/*      } else {*/}
          {/*        return null;*/}
          {/*      }*/}
          {/*    })}*/}
          {/*  </List>*/}
          {/*}*/}
        </Box>

        <Box className={`${styles.SidebarBottomArea ?? ''}`} sx={{ px: 2, pb: 4, mt: 2 }}>
          <List className={`${styles.List ?? ''}`} sx={{ p: 0, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            {addCrumbPermission &&
              <ListItem className={`${styles.ListItem ?? ''}`} sx={{ p: 0, mt: 2, mx: 'auto', textAlign: 'center', width: 'auto' }}>
                <ListItemAvatar className={`${styles.ListItemAvatar ?? ''}`} sx={{ m: 0, cursor: 'pointer', minWidth: 'initial' }}>
                  <Avatar className={`${styles.Avatar ?? ''}`} sx={{ m: 0, width: 45, height: 45 }}>
                    <Button variant="contained" className={`${styles.Button ?? ''}`} sx={{ width: 45, height: 45 }} onClick={() => handleAddCrumb()}>
                      <AddIcon className={`${styles.Icon ?? ''}`} />
                    </Button>
                  </Avatar>
                </ListItemAvatar>
              </ListItem>
            }

            <ListItem className={`${styles.ListItem ?? ''}`} sx={{ p: 0, mt: 2, mx: 'auto', textAlign: 'center', width: 'auto', position: 'relative', zIndex: '1' }}>
              <ListItemAvatar className={`${styles.ListItemAvatar ?? ''}`} sx={{ m: 0, cursor: 'pointer', minWidth: 'initial', position: 'relative', zIndex: '1' }} onMouseEnter={handleMenuOpen}>
                <Avatar className={`${styles.Avatar ?? ''}`} sx={{ m: 0, width: 45, height: 45 }}>
                  <Image src={defaultUser} alt="" />
                </Avatar>
                {selectedCountry &&
                  <Avatar className={`${styles.Country ?? ''}`} sx={{ m: 0, width: 15, height: 15, position: 'absolute', zIndex: '2', right: '0', bottom: '0' }}>
                    <Image src={baseUrl + selectedCountry.path} alt="" />
                  </Avatar>
                }
              </ListItemAvatar>
            </ListItem>
            <ListItem className={`${styles.ListItem ?? ''}`} sx={{ p: 0, mt: 2, mx: 'auto', textAlign: 'center', width: 'auto' }}>
              <MaterialUISwitch
                checked={currentTheme === 'dark'}
                currentTheme={currentTheme}
                onChange={(e) => handleThemeChanged(e)}
              />
            </ListItem>
          </List>
        </Box>
      </Box>

      <Menu
        anchorEl={menuAnchorEl}
        className={`${styles.ProfileMenu ?? ''}`}
        open={menuOpen}
        onClose={handleMenuClose}
        onClick={handleMenuClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            ml: 2,
            mt: 1,
            '&.MuiMenu-paper': {
              borderRadius: '8px',
              backgroundColor: '#fff',

              '*': {
                color: '#010412',
                fontSize: '14px',
                fontWeight: '500',
                '&:hover, &:active, &:focus': {
                  color: '#3C4DE7',
                  backgroundColor: 'transparent',
                }
              },

              '.LogoutButton': {
                backgroundColor: '#CC003D',
                color: '#fff',
              },

              '.SubMenu': {
                '.SubSubMenu': {
                  backgroundColor: '#fff',
                  marginLeft: '10px',
                  borderRadius: '8px',
                  opacity: '0',
                  visibility: 'hidden',
                  transition: '.3s'
                },
                '&:hover .SubSubMenu': {
                  opacity: '1',
                  visibility: 'visible',
                },
              },
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              bottom: '20px',
              left: '-4px',
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'left', vertical: 'bottom' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem sx={{ background: 'tranparent' }}>
          <Button disableRipple sx={{ width: '100%', px: 0, pt: 0, textTransform: 'capitalize', textAlign: 'left', background: 'tranparent', justifyContent: 'flex-start' }} onClick={handleProfileSideBar}>User Profile</Button>
        </MenuItem>
        {selectedCountry &&
          <MenuItem className={`SubMenu`} sx={{ position: 'relative', zIndex: '1' }}>
            <Box sx={{ display: 'flex', alignItems: 'center', textTransform: 'capitalize', textAlign: 'left', justifyContent: 'flex-start' }}>
              <Box sx={{ display: 'flex', alignItems: 'center', mr: 1 }}>{selectedCountry.code ? "MEmob-" + selectedCountry.code : selectedCountry.name}</Box>
              <Avatar sx={{ m: 0, width: 15, height: 15 }}>
                <Image src={baseUrl + selectedCountry.path} alt="" />
              </Avatar>
              {user?.countries?.length > 1 &&
                <Box sx={{ display: 'flex', alignItems: 'center', ml: 1 }}>
                  <ArrowForwardIosIcon sx={{ width: 18, height: 18 }} />
                </Box>
              }
            </Box>
            {user?.countries?.length &&
              <List className={`SubSubMenu`} sx={{ px: 0, py: 1, display: 'flex', flexDirection: 'column', position: 'absolute', zIndex: '2', left: '100%', bottom: '0' }}>
                {user.countries.filter(country => country.isAvailable).map((country, index) => {
                  return (
                    <ListItem className={`SubListItem`} sx={{ px: 2, py: 1 }} key={index}>
                      < Button disableRipple sx={{ p: 0, width: '100%', display: 'flex', alignItems: 'center', textTransform: 'capitalize', textAlign: 'left', justifyContent: 'flex-start', pointerEvents: country.isAvailable ? "" : "none" }} onClick={() => handleCountry(country)}>
                        <Box sx={{ display: 'flex', alignItems: 'center', mr: 1 }}>{country.code ? "MEmob-" + country.code : country.name}</Box>
                        <Avatar sx={{ m: 0, width: 15, height: 15 }}>
                          <Image src={baseUrl + country.path} alt="" sx={{ filter: !country.isAvailable ? 'grayscale(100%)' : '' }} />
                        </Avatar>
                        {selectedCountry.name === country.name &&
                          <Box sx={{ display: 'flex', alignItems: 'center', ml: 1 }}>
                            <CheckIcon sx={{ width: 18, height: 18 }} />
                          </Box>
                        }
                      </Button>
                    </ListItem>
                  )
                })}
              </List>
            }
          </MenuItem>
        }
        <MenuItem disableRipple>
          <Button disableRipple className={`LogoutButton`} sx={{ mt: 1, px: 4, py: 1, borderRadius: '100px', textTransform: 'capitalize' }} onClick={() => handleLogout()}>Log Out</Button>
        </MenuItem>
      </Menu >
    </Box>
  );
}

const mapStateToProps = (state) => ({
  user: state.auth.auth.user,
  selectedCountry: state.auth.selectedCountry,
  currentTheme: state.theme.currentTheme,
  mobileMenuBarState: state.common.mobileMenuBarState
});

export default connect(mapStateToProps, { setSelectedCountry, setCurrentTheme, setCreateCrumbModalState, setAudienceActivityModalState, setUserManagementModalState, setExternalUserManagementModalState, setCompareCardsModalState, setCompareStatusModalState, setProfileSideBarState, setUser, logout})(MenuSideBar);