import React, { useEffect, useState } from "react";
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Divider from '@mui/material/Divider';
import { DialogContentText } from '@mui/material';
import { Box } from '@mui/system';
import Image from 'mui-image';
import {useDispatch, useSelector} from 'react-redux';
import { useWindowWidth } from '../../../../utils/browser';
import { useForm } from 'react-hook-form';
import error_icon from "../../../../assets/images/error_icon.svg";
import { Grid } from "@mui/material";
import TopUpDetails from "../../sidebar/top-up-details-sidebar/TopUpDetails";
import {setContactUsState, setMobileAudienceDetailsState, setTopUpDetailsState} from "../../../../redux/slices/common";
import {useNavigate} from "react-router-dom";
import StripeService from "../../../../services/api/stripe.service";
import ContactUsModal from "../contact-us-modal/ContactUsModal";

function ActivateTopUpCreditModal({ activateTopUpCreditModalState, setActivateTopUpCreditModalState, handleAudienceRefresh }) {

    const browserWidth = useWindowWidth();
    //const [newTopUpDetailModalState, setNewTopUpDetailModalState] = useState(false);
    //const [isCollapsed, setIsCollapsed] = useState(false);
    const [ topUpSliderState, setTopUpSliderState] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { register, handleSubmit, reset, formState: { errors } } = useForm({
        mode: 'onChange'
    });
    const { user } = useSelector((state) => state.auth.auth);
    const { selectedCountry } = useSelector((state) => state.auth);

    const [ amountDetails, setAmountDetails] = useState([]);
    const userFromLocalStorage = JSON.parse(localStorage.getItem('user'));

    const planMode = user?.planMode || userFromLocalStorage?.planMode;

    useEffect(() => {
        let mounted = true;
        // console.log("audienceHistoryChange", audienceHistoryChange);
        if (mounted && user && selectedCountry) {


            const queryCalcParams = [
                `userId=${user.id}`,
                `countryId=${selectedCountry.id}`,
            ];

            StripeService.getAmount(queryCalcParams.join('&')).then((data) => {

                    if (data?.results) {
                        setAmountDetails(data.results);
                    }
                    return Promise.resolve();
                }, (error) => {
                    return Promise.reject();
                }
            )
        }

        return () => {
            mounted = false;
        }
    }, [user, selectedCountry]);



    const handleClose = () => {
        setActivateTopUpCreditModalState(false);
    };

    const handleConfirm = () => {
        setTopUpSliderState(true);
        navigate('/user-profile');
        dispatch(setTopUpDetailsState(true));
        //setActivateTopUpCreditModalState(false);
        //setNewTopUpDetailModalState(true);
    };

    const { currentTheme } = useSelector((state) => state.theme);

    const handleCollapsed = () => {
        //setIsCollapsed(!isCollapsed);
        dispatch(setMobileAudienceDetailsState(false));
    }

    const handleContactUs = () => {

        dispatch(setContactUsState(true));
    };

    return (<>
        <Dialog
            open={activateTopUpCreditModalState}
            onClose={handleClose}
            PaperProps={{ sx: {  width: "600px", borderRadius: "0px" } }}
            sx={{
                backdropFilter: "blur(25px)",
            }}
        >
            <DialogContent sx={{ px: browserWidth > 500 ? 7.5 : 3 , overflow: "hidden"}}>
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Box sx={{ width: 120, height: 100 }}>
                        <Image src={ error_icon } alt='logo' />
                    </Box>
                </Box>
                {/*<DialogTitle id="alert-dialog-title" textAlign="center" fontWeight={"bold"} fontSize={"25px"}>*/}
                {/*    {"Refresh"}*/}
                {/*</DialogTitle>*/}
                <DialogContentText
                    style={{ paddingTop: '5px' }}
                    id="alert-dialog-description"
                    textAlign="center"
                    color="#6F7288"
                >
                            Sorry, You do not have enough Credit.
                </DialogContentText>
                <Grid container rowSpacing={0} columnSpacing={{ xs: 0.5, sm: 2, md: 3 }} style={{ marginLeft: '5px' }}>
                        <>
                            { planMode === 'Trial' ? (
                                <>
                                <Grid style={{ marginLeft: '26px', padding: '20px',  }} item display={"flex"} alignItems={"center"}>
                                    <Box style={{ fontSize: '18px' }}>Available Cards:</Box>
                                </Grid>
                                <Grid item  style={{ marginLeft: '2px' }} display={"flex"} alignItems={"center"}>
                                    <Box style={{ color: '#39BF8F', fontSize: '18px' }}>
                                        {amountDetails.totalCreditLimit !== undefined
                                            ? `${amountDetails.totalCreditLimit.toLocaleString()}`
                                            : '0'}
                                    </Box>
                                </Grid>
                               </>
                                ) : (
                                <>
                            <Grid style={{ marginLeft: '26px', padding: '20px',  }} item display={"flex"} alignItems={"center"}>
                                <Box style={{ fontSize: '18px' }}>Available Credits:</Box>
                            </Grid>
                                <Grid item  style={{ marginLeft: '2px' }} display={"flex"} alignItems={"center"}>
                                <Box style={{ color: '#39BF8F', fontSize: '18px' }}>
                            {amountDetails.cardTotalPlanLimit !== undefined
                                ? `$ ${amountDetails.cardTotalPlanLimit.toLocaleString()}`
                                : '0'}
                                </Box>
                                </Grid>
                            </>
                                ) }

                        </>
                </Grid>
            </DialogContent>
            <Divider />
            <DialogActions sx={{ padding: "0px" }}>
                    <ButtonGroup fullWidth size="large" aria-label="outlined primary button group">
                        { planMode !== 'Trial' ? (<>
                        <Button variant="text" onClick={handleClose} sx={{ textTransform: "none", borderRadius: "0px" }}>
                            No
                        </Button>
                        <Button className="form-button" variant="contained" onClick={handleConfirm} sx={{ textTransform: "none", borderRadius: "0px" }}>
                            Top up credits
                        </Button></>
                ) : (<>
                            <Button variant="text" onClick={handleClose} sx={{ textTransform: "none", borderRadius: "0px" }}>
                                Cancel
                            </Button>
                <Button className="form-button" variant="contained" onClick={handleContactUs} sx={{ textTransform: "none", borderRadius: "0px" }}>
                     Contact Sales cr@memob.com for more plans
                </Button>
                            </>)}
                    </ButtonGroup>
            </DialogActions>
        </Dialog>

        <ContactUsModal/>
    </>);
}

export default ActivateTopUpCreditModal;