import React, { useState } from 'react';
import { useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import { useDispatch, useSelector } from 'react-redux';
import { Autocomplete, Box, Button, ButtonGroup, Checkbox, CircularProgress, DialogActions, DialogContent, FormControlLabel, List, ListItem, ListItemText, TextField } from '@mui/material';

import { setFilterPoiModalState, setMessageSnackbarStateState } from '../../../../redux/slices/common';
import PoiService from '../../../../services/api/poi.service';
import { LoadingButton } from '@mui/lab';
import { useForm } from 'react-hook-form';
import { setMessage } from '../../../../redux/slices/message';

function FilterPoiModal() {
  const { filterPoiModalState } = useSelector((state) => state.common);
  const { selectedCountry } = useSelector((state) => state.auth);
  const [category, setCategory] = useState("");
  const [city, setCity] = useState("");
  const [resultData, setResultData] = useState("");
  const [poiMessage, setPoiMessage] = useState("");
  const [isLibraryLoading, setIsLibraryLoading] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState({ selectedCategory: "", selectedCity: "" });
  const { register, handleSubmit, reset, formState: { errors } } = useForm({
    mode: 'onChange'
  });

  const dispatch = useDispatch();

  const poiDetails = async () => {
    try {
      if (selectedCountry) {
        const queryParams = [
          `countryId=${selectedCountry.id}`,
        ];
        const data = await PoiService.poiDetailsCategory(queryParams.join('&'));
        setIsLibraryLoading(false);
        if (data?.results?.length) {
          setCategory(data.results);
        }
      }
    } catch (error) {
      setIsLibraryLoading(false);
      console.log(error);
    }

    try {
      if (selectedCountry) {
        // const queryParams = [
        //   `countryId=${selectedCountry.id}`,
        // ];
        // const data = await PoiService.poiDetailsCites(queryParams.join('&'));
        // setIsLibraryLoading(false);
        // if (data?.results?.length) {
        //   setCity(data.results);
        // }
      }
    } catch (error) {
      setIsLibraryLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    if (filterPoiModalState) {
      poiDetails();
    }
  }, [filterPoiModalState, selectedCountry]);

  // useEffect(() => {
  //   let mounted = true;
  //   if (mounted) {
  //     if (selectedCountry) {
  //       setIsLibraryLoading(true);
  //       const queryParams = [
  //         // `userId=${user.id}`,
  //         `countryId=${selectedCountry.id}`,
  //       ]
  //       PoiService.poiDetailsCategory(queryParams.join('&')).then(
  //         (data) => {
  //           setIsLibraryLoading(false);
  //           if (data) {
  //             setCategory(data.results);
  //           }
  //           return Promise.resolve();
  //         },
  //         (error) => {
  //           setIsLibraryLoading(false);
  //           return Promise.reject();
  //         }
  //       )

  //       PoiService.poiDetailsCites(queryParams.join('&')).then(
  //         (data) => {
  //           setIsLibraryLoading(false);
  //           if (data) {
  //             setCity(data.results);
  //           }
  //           return Promise.resolve();
  //         },
  //         (error) => {
  //           setIsLibraryLoading(false);
  //           return Promise.reject();
  //         }
  //       )
  //     }
  //   }

  //   return () => {
  //     mounted = false;
  //   }
  // }, [selectedCountry, dispatch]);

  const handleClose = (e, reason) => {
    if (reason !== "backdropClick") {
      setSelectedOptions({ selectedCategory: "", selectedCity: "" });
      setPoiMessage([]);
      dispatch(setFilterPoiModalState(false));
    }
  };

  const handleBackdropClick = (event) => {
    event.stopPropagation();
    return false;
  };

  const handleToggle = (index) => () => {
    const currentIndex = selectedItems.indexOf(index);
    const newSelectedItems = [...selectedItems];

    if (currentIndex === -1) {
      newSelectedItems.push(index);
    } else {
      newSelectedItems.splice(currentIndex, 1);
    }

    setSelectedItems(newSelectedItems);
  };

  const onChangeOption = (newValue, type) => {
    setSelectedOptions(prevOptions => ({
      ...prevOptions,
      [type]: newValue
    }));
    onChange(newValue, type);
  };

  const onChange = (newValue, type) => {
    console.log(selectedOptions)
    setSelectedItems([]);
    setSelectAll(false);
    setIsLibraryLoading(true);

    const queryParams = [
      `countryId=${selectedCountry.id}`
    ];

    if (type === 'selectedCategory' && newValue && newValue.categoryName) {
      queryParams.push(`category=${newValue.categoryName}`);
    } else if (type !== 'selectedCategory' && selectedOptions.selectedCategory?.categoryName) {
      queryParams.push(`category=${selectedOptions.selectedCategory.categoryName}`);
    }

    if (type === 'selectedCity' && newValue && newValue.cityName) {
      queryParams.push(`city=${newValue.cityName}`);
    } else if (type !== 'selectedCity' && selectedOptions.selectedCity?.cityName) {
      queryParams.push(`city=${selectedOptions.selectedCity.cityName}`);
    }

    PoiService.poiDetails(queryParams.join('&')).then(
      (data) => {
        setIsLibraryLoading(false);
        if (data) {
          console.log(data.message);
          setResultData(data.results);
          setPoiMessage(data.message);
        }
        return Promise.resolve();
      },
      (error) => {
        setIsLibraryLoading(false);
        return Promise.reject();
      }
    )

  };

  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedItems([]);
    } else {
      const allItems = resultData.map((_, index) => index);
      setSelectedItems(allItems);
    }
    setSelectAll(!selectAll);
  };

  const onSubmit = (values) => {
    setIsSubmitting(true);
    const selectedResultData = selectedItems.map((index) => resultData[index]);

    const selectedIds = selectedResultData.map((item) => item.id).join(',');
    if (selectedIds) {
      const queryParams = [
        // `userId=${user.id}`,
        `countryId=${selectedCountry.id}`,
        `poiDetailList=${selectedIds}`
      ]

      PoiService.poiDetailsDownload(queryParams.join('&')).then(
        (data) => {
          setIsSubmitting(false);
          const url = URL.createObjectURL(new Blob([data], { type: "text/csv" }));
          const link = document.createElement("a");
          link.href = url;
          const timestamp = new Date().toISOString().replace(/[-:.]/g, "").replace("T", "_").split(".")[0];
          const filename = `${values.category}_${values.city}_${timestamp}`;
          link.setAttribute("download", filename);
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
        }).catch((error) => {
          setIsSubmitting(false);
          dispatch(setMessage({
            type: "error",
            title: error.response.data.message,
          }));
          dispatch(setMessageSnackbarStateState(true));
        });
    } else {
      setIsSubmitting(false);
      dispatch(setMessage({
        type: "error",
        title: "Please Selecte an item",
      }));
      dispatch(setMessageSnackbarStateState(true));

    }
  }

  return (<>
    <Dialog
      open={filterPoiModalState}
      onClose={handleClose}
      onBackdropClick={handleBackdropClick}
      PaperProps={{ sx: { width: "420px", borderRadius: "0px" } }}
      sx={{
        backdropFilter: "blur(25px)",
      }}
    >

      <DialogTitle textAlign="center" sx={{ p: 3, fontSize: 16, lineHeight: '20px' }}>System Locations Filter</DialogTitle>
      <Divider />
      <Box
        component='form'
        noValidate
        autoComplete='off'
        onSubmit={handleSubmit(onSubmit)}
        onBackdropClick={handleBackdropClick}
      >
        <DialogContent>

          <Autocomplete
            fullWidth
            options={category || []}
            value={selectedOptions.selectedCategory || null}
            getOptionLabel={(option) => option.categoryName}
            onChange={(event, newValue) => {
              onChangeOption(newValue, 'selectedCategory');
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder={"Select a category"}
                variant="standard"
                sx={{ my: 2, color: '#fff' }}
                {...register("category")}
                disabled={category && category.length === 0}
              />
            )}
            PaperProps={{
              style: {
                maxHeight: '50vh',
                maxWidth: '50vh',
                marginTop: '2px',
                overflowX: 'auto',
              },
            }}
          />

          <Autocomplete
            fullWidth
            options={city || []}
            value={selectedOptions.selectedCity || null}
            getOptionLabel={(option) => option.cityName}
            onChange={(event, newValue) => {
              onChangeOption(newValue, 'selectedCity');
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Select a city"
                variant="standard"
                sx={{ my: 2, color: '#fff' }}
                {...register("city")}
                disabled={city && city.length === 0}
              />
            )}
            paperprops={{
              style: {
                maxHeight: '50vh',
                maxWidth: '50vh',
                marginTop: '2px',
                overflowX: 'auto',
              },
            }}
          />

          {isLibraryLoading ?
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 150 }}>
              <CircularProgress />
            </Box>
            :
            <Box
              sx={{
                maxHeight: '290px',
                overflow: 'auto',
                '&::-webkit-scrollbar': {
                  width: '8px',
                },
                '&::-webkit-scrollbar-track': {
                  background: '#f1f1f1',
                },
                '&::-webkit-scrollbar-thumb': {
                  background: '#888',
                  borderRadius: '4px',
                },
                '&::-webkit-scrollbar-thumb:hover': {
                  background: '#555',
                },
              }}
            >
              {resultData && poiMessage === "POI Details Available" ?
                <FormControlLabel
                  className="form-control"
                  control={
                    <Checkbox
                      checked={selectAll}
                      onChange={handleSelectAll}
                      sx={{ mr: '6px' }}
                      disableRipple
                    />
                  }
                  label="Select All"
                /> :
                ""
              }

              {resultData && poiMessage === "POI Details Available" ?
                <List>
                  {resultData.map((item, index) => (
                    <ListItem key={index} disablePadding sx={{ justifyContent: 'space-between' }}>
                      <Box sx={{ display: 'flex', alignItems: 'start' }}>
                        <FormControlLabel
                          sx={{ ml: '2px', mr: '0' }}
                          onClick={handleToggle(index)}
                          className="form-control"
                          control={
                            <Checkbox
                              edge="start"
                              checked={selectedItems.includes(index)}
                              tabIndex={-1}
                              disableRipple
                              inputProps={{ 'aria-labelledby': 'labelId' + index }}
                            />
                          }
                        />
                        <ListItemText
                          id={'labelId' + index}
                          primary={item.name}
                          // secondary={item.address}
                          sx={{ p: '6px' }}
                        />
                      </Box>
                    </ListItem>
                  ))}
                </List> :
                <Box>{poiMessage}</Box>
              }
              <input type="hidden" name="selectedResultData" value={JSON.stringify(selectedItems)} />

            </Box>
          }
        </DialogContent>
        <Divider />
        <DialogActions sx={{ padding: "0px" }}>
          <ButtonGroup fullWidth size='large' aria-label="outlined primary button group">
            <Button variant='flat' onClick={handleClose} sx={{ textTransform: "none", borderRadius: "0px" }}>Cancel</Button>
            <LoadingButton
              variant='contained'
              fullWidth
              type='submit'
              loading={isSubmitting}
              className="form-button"
              sx={{ textTransform: "none", borderRadius: "0px" }}
            >
              Download
            </LoadingButton>
          </ButtonGroup>
        </DialogActions>
      </Box>
    </Dialog>
  </>);
}

export default FilterPoiModal;