import axios from 'axios';
import { apiUrl, accessToken, refreshToken, accessTokenTimeout, refreshTokenTimeout } from '../../config/config';
import { deleteCookie, getCookie, setCookie } from '../../utils/cookie';

const api = axios.create({
  baseURL: apiUrl,
});

const authApi = axios.create({
  baseURL: apiUrl,
});

authApi.interceptors.request.use(
  async (config) => {
    let accessTokenNew = getCookie(accessToken);

    // if (!accessTokenNew) {
    //   const refreshTokenNew = getCookie(refreshToken);
    //   if (refreshTokenNew) {
    //     const formData = {
    //       refreshToken: refreshTokenNew
    //     };
    //     await axios.post(apiUrl + "all/auth/refreshtoken", formData)
    //       .then((response) => {
    //         // console.log('signIn interceptors', response.data);
    //         if (response?.data?.accessToken) {
    //           setCookie(accessToken, response.data.accessToken, accessTokenTimeout);
    //           setCookie(refreshToken, response.data.refreshToken, refreshTokenTimeout);
    //           accessTokenNew = response.data.accessToken;
    //         } else {
    //           deleteCookie(accessToken);
    //           deleteCookie(refreshToken);
    //           window.location = "/";
    //         }
    //       })
    //       .catch((error) => {
    //         deleteCookie(accessToken);
    //         deleteCookie(refreshToken);
    //         window.location = "/";
    //       });
    //   } else {
    //     deleteCookie(accessToken);
    //     deleteCookie(refreshToken);
    //     window.location = "/";
    //   }
    // }

    config.headers.Authorization = `Bearer ${accessTokenNew}`;
    return config;
  },
  (err) => {
    console.error(err);
    return Promise.reject(err);
  },
);


authApi.interceptors.response.use(
    (response) => {
        // Pass the response data for successful requests
        return response;
    },
    (error) => {
        if (error.response) {
            const status = error.response.status;

            // Handle plan expiry (403 and specific message)
            if (status === 401 && error.response.data?.message === "Plan expired") {
                alert("Your plan has expired. Please renew your subscription.");
                // Clear cookies and redirect the user
                deleteCookie(accessToken);
                deleteCookie(refreshToken);
                window.location = "/";
                return Promise.reject(error);
            }

            // Handle other errors if necessary (optional)
            if (status === 401) {
                console.warn("Unauthorized access, please check authentication.");
                // Optionally, log the user out here or handle token refresh
            }
        }

        // Reject the error to ensure calling code knows of the failure
        return Promise.reject(error);
    }
);

export { api, authApi };
