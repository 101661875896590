import React from 'react';
import Box from '@mui/material/Box';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';
import InputAdornment from "@mui/material/InputAdornment";
import Stack from '@mui/material/Stack';
import { Image } from 'mui-image'

import mail from "../../../../assets/images/icons/svg/sms.svg";
import Compicon from "../../../../assets/images/icons/svg/office.svg";
import defaultUser from "../../../../assets/images/user.png";

import { connect, useDispatch, useSelector } from 'react-redux';
import { setProfileSideBarState, setEditProfileSideBarState } from '../../../../redux/slices/common';
import { baseUrl } from '../../../../config/config';
import { Divider } from '@mui/material';

function ProfileSideBar({ user, currentTheme }) {

  // console.log("user po", user);

  const { profileSideBarState } = useSelector((state) => state.common);

  const dispatch = useDispatch();

  const handleOpen = () => {
    dispatch(setProfileSideBarState(true));
  };

  const handleClose = () => {
    dispatch(setProfileSideBarState(false));
  };

  const handleEditProfile = () => {
    dispatch(setEditProfileSideBarState(true));
    dispatch(setProfileSideBarState(false));
  };

  return (<>
    <SwipeableDrawer
      anchor={"right"}
      open={profileSideBarState}
      onClose={handleClose}
      onOpen={handleOpen}
      PaperProps={{
        sx: {
          width: "45%"
        }
      }}
      sx={{
        backdropFilter: "blur(2px)",
      }}
    >
      {user &&
        <Box
          sx={{
            my: 8,
            mx: 7,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar
            sx={{ bgcolor: 'secondary.main', width: 120, height: 120 }}
          >
            <Image src={user?.path ? user.path : defaultUser} />
          </Avatar>

          <Chip label="Enterprise" variant="contained" color='primary' spacing={10} sx={{ transform: 'translateY(-20px)' }} />

          <Chip label="Personal Inforamtion" component="h1" variant="contained" className="personal-info-chip" />

          <Box component="form" noValidate sx={{ mt: 1, width: '100% ', display: 'block' }} >
            <Typography component="h3" variant="contained">
              {user.name}
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Grid container>
              <Grid item xs={6}>
                <TextField
                  id="email-label"
                  name="email-label"
                  value={'Email'}
                  variant="outlined"
                  sx={{ border: 'none', "& fieldset": { border: 'none' } }}
                  InputProps={{
                    style: { color: "#6F7288" },
                    startAdornment: (
                      <InputAdornment position="start">
                        <Image src={mail} alt="mail" style={{ width: 20, height: 20, objectFit: 'contain' }} />
                      </InputAdornment>
                    )
                  }}
                  inputProps={
                    { readOnly: true, }
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <Box sx={{ p: 2 }}>{user?.email ? user.email : ""}</Box>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id="company-textfield"
                  name="company-textfield"
                  value={'Company'}
                  variant="outlined"
                  sx={{ border: 'none', "& fieldset": { border: 'none' } }}
                  InputProps={{
                    style: { color: "#6F7288" },
                    startAdornment: (
                      <InputAdornment position="start">
                        <Image src={Compicon} alt="companyicon" style={{ width: 20, height: 20, objectFit: 'contain' }} />
                      </InputAdornment>
                    )
                  }}
                  inputProps={
                    { readOnly: true, }
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <Box sx={{ p: 2 }}>{user?.companyName ? user.companyName : ""}</Box>
              </Grid>
              {user?.countries?.length &&
                <Stack direction="row" sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', width: '100%' }}>
                  <List sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                    {user.countries.map((country, index) => {
                      return (
                        <ListItem disablePadding key={index} sx={{ mx: 1 }}>
                          <Avatar sx={{ transform: 'scale(.7)' }}>
                            <img src={baseUrl + country.path} alt="#" />
                          </Avatar>
                          <ListItemText sx={{ padding: "10px", color: "#6F7288" }}>{country.code ? country.code : country.name}</ListItemText>
                        </ListItem>
                      )
                    })}
                  </List>
                </Stack>
              }
              <Grid
                item
                container
                // alignItems="center"
                // justifyContent="center"
                margin={'50px'}
              >
                <Button variant='contained' fullWidth sx={{ textTransform: "none", marginBottom: '20px' }} onClick={handleEditProfile}>Edit Profile</Button>
                <Divider sx={{ width: '100%', my: 2 }} />
                <Typography variant="h6" sx={{ fontWeight: '700', mb: 2, textAlign: 'left' }}>
                  Have questions?
                </Typography>
                <Typography variant="body2" color={'#6F7288'} >
                    Please <a href="https://google.com">contact us</a> for any inquiry or feedback about our platform and our team will promptly respond.
                  </Typography>
                <Button variant='contained' fullWidth color='warning' sx={{ textTransform: "none", marginTop: '20px' }}>Contact us</Button>
              </Grid>
            </Grid>
          </Box>
        </Box>
      }
    </SwipeableDrawer>
  </>);
}

const mapStateToProps = (state) => ({
  user: state.auth.auth.user,
  currentTheme: state.theme.currentTheme
});

export default connect(mapStateToProps, null)(ProfileSideBar);