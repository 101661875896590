import React, { useState, useRef } from "react";
import { Box, Dialog } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import lock from "../../../../assets/images/icons/svg/lock.svg";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { IconButton, TextField } from "@mui/material";
import { useForm } from "react-hook-form";
import { LoadingButton } from "@mui/lab";
import { connect, useDispatch, useSelector } from 'react-redux';
import { setChangePasswordState } from '../../../../redux/slices/common';
import Image from "mui-image";
import UserService from "../../../../services/api/user.service";

function ChangePasswordModal({ user }) {

  const { changePasswordState } = useSelector((state) => state.common);

  const dispatch = useDispatch();
  const myFormRef = useRef(null);

  const handleClearForm = () => {
    myFormRef.current.reset();
  }

  const handleClose = () => {
    dispatch(setChangePasswordState(false));
  };

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [passwordMode, setPasswordMode] = useState({
    oldPassword: "password",
    password: "password",
    confirmPassword: "password",
  });

  const { register, handleSubmit, formState: { errors } } = useForm({
    mode: 'onChange'
  });

  const handlePasswordMode = (state) => {
    let passwordModeDummy = { ...passwordMode };
    if (passwordModeDummy[state] === "password") {
      passwordModeDummy[state] = "text";
    } else {
      passwordModeDummy[state] = "password";
    }
    setPasswordMode(passwordModeDummy);
  };

  const onSubmit = (values) => {
    // console.log("values", values);
    const formData = {
      password: values.pass,
      confirmPassword: values.confirm_pass,
      oldPassword: values.oldPassword,
      userId: user.id
    };
    // console.log("Form Data" + formData.confirmPassword)

    setIsSubmitting(true);
    UserService.changePassword(formData).then(
      (data) => {
        if (data) {
          setIsSubmitting(false);
          handleClose();
          handleClearForm();
        }
        return Promise.resolve();
      },
      (error) => {
        setIsSubmitting(false);
        console.log(error);
        return Promise.reject();
      }
    );
  };

  return (<>
    <Dialog
      open={changePasswordState}
      onClose={handleClose}
      PaperProps={{ sx: { width: "35%", borderRadius: "0px" } }}
      sx={{
        backdropFilter: "blur(25px)",
      }}
    >
      <Box className="flex-container">
        <Box className="form-container-changepasswordmodal">
          <Box className="login-title" sx={{ textAlign: 'center', mb: 6, fontSize: 32, lineHeight: '40px', fontWeight: 700 }}>Change password</Box>
          <Box
            component='form'
            noValidate
            autoComplete='off'
            ref={myFormRef}
            onSubmit={handleSubmit(onSubmit)}
          >
            <Box>
              <Box className="form-label" sx={{ fontSize: 18, fontWeight: 400, mb: 3 }}>Old Password</Box>
              <TextField
                id="oldPassword"
                autoFocus
                required
                fullWidth
                sx={{ mb: 4 }}
                type={passwordMode.oldPassword}
                variant="standard"
                placeholder="Old Password"
                error={!!errors['oldPassword']}
                helperText={errors['oldPassword'] ? errors['oldPassword'].message : ''}
                {...register("oldPassword", {
                  required: "Old Password required"
                })}
                InputProps={{
                  style: { paddingBottom: "12px", fontSize: "18px" },
                  className: "form-control",
                  startAdornment: (
                    <InputAdornment position="start" sx={{ ml: 1, mr: 2 }}>
                      <Image src={lock} alt="lock" style={{ width: 20, height: 20, objectFit: 'contain' }} />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => handlePasswordMode("oldPassword")}
                        aria-label="toggle password"
                        edge="end"
                        sx={{ ml: 2, mr: 0.5, color: "grey" }}
                      >
                        {passwordMode.password === "password" ? (
                          <VisibilityOffIcon />
                        ) : (
                          <VisibilityIcon />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Box>

            <Box>
              <Box className="form-label" sx={{ fontSize: 18, fontWeight: 400, mb: 3 }}>New Password</Box>
              <TextField
                id="pass"
                required
                fullWidth
                sx={{ mb: 4 }}
                type={passwordMode.password}
                variant="standard"
                placeholder="Password"
                error={!!errors['pass']}
                helperText={errors['pass'] ? errors['pass'].message : ''}
                {...register("pass", {
                  required: "Password required"
                })}
                InputProps={{
                  style: { paddingBottom: "12px", fontSize: "18px" },
                  className: "form-control",
                  startAdornment: (
                    <InputAdornment position="start" sx={{ ml: 1, mr: 2 }}>
                      <Image src={lock} alt="lock" style={{ width: 20, height: 20, objectFit: 'contain' }} />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => handlePasswordMode("password")}
                        aria-label="toggle password"
                        edge="end"
                        sx={{ ml: 2, mr: 0.5, color: "grey" }}
                      >
                        {passwordMode.password === "password" ? (
                          <VisibilityOffIcon />
                        ) : (
                          <VisibilityIcon />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Box>

            <Box>
              <Box className="form-label" sx={{ fontSize: 18, fontWeight: 400, mb: 3 }}>Confirm Password</Box>
              <TextField
                id="confirm_pass"
                required
                fullWidth
                sx={{ mb: 4 }}
                type={passwordMode.confirmPassword}
                variant="standard"
                placeholder="Password"
                error={!!errors['confirm_pass']}
                helperText={errors['confirm_pass'] ? errors['confirm_pass'].message : ''}
                {...register("confirm_pass", {
                  required: "Confirm Password required"
                })}
                InputProps={{
                  style: { paddingBottom: "12px", fontSize: "18px" },
                  className: "form-control",
                  startAdornment: (
                    <InputAdornment position="start" sx={{ ml: 1, mr: 2 }}>
                      <Image src={lock} alt="lock" style={{ width: 20, height: 20, objectFit: 'contain' }} />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => handlePasswordMode("confirmPassword")}
                        aria-label="toggle password"
                        edge="end"
                        sx={{ ml: 2, mr: 0.5, color: "grey" }}
                      >
                        {passwordMode.confirmPassword === "password" ? (
                          <VisibilityOffIcon />
                        ) : (
                          <VisibilityIcon />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
            <Box sx={{ textAlign: 'center' }}>
              <LoadingButton
                variant='contained'
                type='submit'
                loading={isSubmitting}
                className="form-button"
                sx={{ py: '0.8rem', mt: 3.5, textTransform: 'capitalize', fontSize: 20, fontWeight: 700, borderRadius: 1.5, width: '90%' }}
              >
                Change Password
              </LoadingButton>
            </Box>

          </Box>
        </Box>
      </Box>
    </Dialog>
  </>);
}

const mapStateToProps = (state) => ({
  user: state.auth.auth.user
});

export default connect(mapStateToProps, null)(ChangePasswordModal);