import React, { useEffect, useMemo } from 'react';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import { Box } from '@mui/system';
import { Autocomplete, TextField } from '@mui/material';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LoadingButton } from '@mui/lab';
import { useForm } from 'react-hook-form';
import { useWindowWidth } from '../../../../utils/browser';
import { setMessage } from '../../../../redux/slices/message';
import { setMessageSnackbarStateState } from '../../../../redux/slices/common';
import CrumbService from "../../../../services/api/crumb.service";


function EditCrumbModal({ crumbId ,crumbName, editCrumbModalState, setEditCrumbModalState, handleCrumbEdit }) {
    const dispatch = useDispatch();
    const browserWidth = useWindowWidth();
    const { selectedCountry } = useSelector((state) => state.auth);
    const { user } = useSelector((state) => state.auth.auth);
    const [rename, setRename] = useState(crumbName);
    const { currentTheme } = useSelector((state) => state.theme);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const { register, handleSubmit, reset, formState: { errors } } = useForm({
        mode: 'onChange'
    });

    useEffect(() => {
        setRename(crumbName);

    }, [user, selectedCountry, editCrumbModalState, crumbName])


    const handleClose = () => {
        dispatch(setEditCrumbModalState(false));
    };

    const handleKeyDown = (e) => {
        // console.log("values", event);
        if (e.key === 'Enter') {
            e.preventDefault();
            onSubmit();
        }
    };


    const onSubmit = () => {

        handleCrumbEdit(crumbId, rename, setEditCrumbModalState, editCrumbModalState);
    }

    return (<>
        <Dialog
            open={editCrumbModalState}
            onClose={handleClose}
            // onBackdropClick={handleBackdropClick}
            PaperProps={{ sx: { width: "390px", borderRadius: "0px" } }}
            sx={{
                backdropFilter: "blur(25px)",
            }}
        >
            <DialogTitle textAlign="center" sx={{ p: 3, fontSize: 16, lineHeight: '20px' }}>Edit Crumb</DialogTitle>
            <Box
                component='form'
                noValidate
                autoComplete='off'
                onSubmit={handleSubmit(onSubmit)}
            >
                <DialogContent sx={{ px: browserWidth > 500 ? 7.5 : 3 }}>
                    <Box>
                        <TextField
                            autoFocus
                            InputProps={{
                                style: { padding: "0 8px 10px 8px", fontSize: "16px" },
                            }}
                            InputLabelProps={{
                                style: { color: "#6F7288" }
                            }}
                            id="audience_name"
                            type="text"
                            variant="standard"
                            fullWidth
                            sx={{ my: 1.5 }}
                            value={rename}
                            onChange={(e) => setRename(e.target.value)}
                            onKeyDown={(e) => handleKeyDown(e)}
                            placeholder="Crumb Name"
                        />


                    </Box>
                </DialogContent>
                <Divider />
                <DialogActions sx={{ padding: "0px" }}>
                    <ButtonGroup fullWidth size='large' aria-label="outlined primary button group">
                        <Button variant='flat' onClick={handleClose} style={{ textTransform: "none", borderRadius: "0px" }}>Cancel</Button>
                        <LoadingButton
                            variant='contained'
                            fullWidth
                            type='submit'
                            loading={isSubmitting}
                            className="form-button"
                            sx={{ textTransform: "none", borderRadius: "0px" }}
                        >
                            Ok
                        </LoadingButton>
                    </ButtonGroup>
                </DialogActions>
            </Box>
        </Dialog>
    </>);
}

export default EditCrumbModal;