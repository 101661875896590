import React from 'react';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import { DialogContentText } from '@mui/material';
import { Box } from '@mui/system';
import Image from 'mui-image';
import {useDispatch, useSelector} from 'react-redux';
import { useWindowWidth } from '../../../../utils/browser';
import { useForm } from 'react-hook-form';
import checkCircle from "../../../../assets/images/check-circle.png";
import darkCheckCircle from "../../../../assets/images/check-circle-black.png";

import Grid from "@mui/material/Grid";
import FormControlLabel from "@mui/material/FormControlLabel";
import AudienceService from "../../../../services/api/audience.service";
import {setMessage} from "../../../../redux/slices/message";
import {setMessageSnackbarStateState} from "../../../../redux/slices/common";
import {useNavigate} from "react-router-dom";
import {setCampaignAudiences} from "../../../../redux/slices/audience";

function NewActivateConfirmModal({ audience, newActivateConfirmModalState, setNewActivateConfirmModalState, connectorData, connectorCount }) {

    const browserWidth = useWindowWidth();
    const dispatch = useDispatch();
    const { register, handleSubmit, reset, formState: { errors } } = useForm({
        mode: 'onChange'
    });
    const { user } = useSelector((state) => state.auth.auth);
    const { selectedCountry } = useSelector((state) => state.auth);
    const { campaignAudiences, totalSelectedAudience } = useSelector((state) => state.audience);
    const navigate = useNavigate();

    const handleClose = () => {
        setNewActivateConfirmModalState(false);
    };

    const handleConfirm = () => {
        //handleAudienceRefresh(audience);

        const filteredConnectors = connectorData.filter((con) => con.isOn);

        const connectorIds = filteredConnectors.map(con => con.id).join(',');

        const filteredAudience = campaignAudiences.filter((aud) => aud.isAdd);
        let audienceIds = filteredAudience.map(aud => aud.id);

        if (audience && audience.audienceId) {

            audienceIds.push(audience.audienceId);
        }

        audienceIds = audienceIds.join(',');

        let count = totalSelectedAudience * (connectorCount === 0 ?  1 : connectorCount);

        const queryParams = [

            `partnerIdList=${connectorIds}`,
            `userId=${user.id}`,
            `audienceIds=${audienceIds}`,
            `countryId=${selectedCountry.id}`,
            `audienceCount=${count}`
        ];


        AudienceService.v2activateCampaign(queryParams.join('&'))
            .then(
                (data) => {
                    setNewActivateConfirmModalState(false);
                    dispatch(setMessage({
                        type: "success",
                        title: "Activated Successfully",
                    }));
                    dispatch(setMessageSnackbarStateState(true));
                    navigate('/home');
                    dispatch(setCampaignAudiences([]));
                },
                (error) => {
                    console.log(error);
                }
            );


    };

    const { currentTheme } = useSelector((state) => state.theme);

    return (
        <>
            <Dialog
                open={newActivateConfirmModalState}
                onClose={handleClose}
                PaperProps={{ sx: { width: "550px", borderRadius: "0px" } }}
                sx={{
                    backdropFilter: "blur(25px)",
                }}
            >
                <DialogContent sx={{ px: browserWidth > 500 ? 7.5 : 3 }}>
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                        <Box sx={{ width: 100, height: 100 }}>
                            <Image
                                src={currentTheme === "dark" ? checkCircle : darkCheckCircle}
                                alt="logo"
                            />
                        </Box>
                    </Box>
                    <DialogContentText
                        id="alert-dialog-description"
                        sx={{
                            paddingTop: "10px",
                            textAlign: "center",
                            color: currentTheme === "dark" ? "#ffffff" : "#6F7288",
                        }}
                    >
                        <Box
                            sx={{
                                backgroundColor: currentTheme === "dark" ? "#2C2F36" : "#f9f9f9",
                                color: currentTheme === "dark" ? "#AAB0BC" : "#505560",
                                padding: "12px",
                                borderRadius: "8px",
                                fontSize: "14px",
                                marginBottom: "15px",
                                textAlign: "center",
                                border: currentTheme === "dark" ? "1px solid #39404D" : "1px solid #E0E3E7",
                            }}
                        >
                            <p>
                                <strong>Note:</strong> For optimal reach and results, it is recommended to activate across your preferred channels with an audience size of at least{" "}
                                <strong>5,000 device IDs</strong>. Larger audiences tend to perform better across various platforms.
                            </p>
                            <p>
                                Audiences below this size may experience reduced campaign effectiveness or limited visibility. Consider increasing your audience size to achieve more impactful results.
                            </p>
                        </Box>
                        Are you sure you want to Activate Campaign?
                    </DialogContentText>
                </DialogContent>
                <Divider sx={{ borderColor: currentTheme === "dark" ? '#11121D' : '#D9D9D9' }} variant="fullWidth" />
                <DialogActions sx={{ padding: "0px" }}>
                    <ButtonGroup
                        fullWidth
                        size="large"
                        aria-label="outlined primary button group"
                    >
                        <Button
                            variant="flat"
                            onClick={handleClose}
                            sx={{
                                textTransform: "none",
                                borderRadius: "0px",
                                color: currentTheme === "dark" ? "#AAB0BC" : "#202020",
                            }}
                        >
                            No
                        </Button>
                        <Button
                            className="form-button"
                            variant="contained"
                            onClick={handleConfirm}
                            sx={{
                                textTransform: "none",
                                borderRadius: "0px",
                                backgroundColor: currentTheme === "dark" ? "#39BF8F" : "#1976d2",
                                "&:hover": {
                                    backgroundColor: currentTheme === "dark" ? "#34A37A" : "#115293",
                                },
                            }}
                        >
                            Yes
                        </Button>
                    </ButtonGroup>
                </DialogActions>
            </Dialog>
        </>
    );

}

export default NewActivateConfirmModal;