import React, { useEffect, useMemo } from 'react';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import { Box } from '@mui/system';
import { Autocomplete, TextField } from '@mui/material';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LoadingButton } from '@mui/lab';
import { useForm } from 'react-hook-form';
import { useWindowWidth } from '../../../../utils/browser';
import InputAdornment from "@mui/material/InputAdornment";
import Image from "mui-image";
import mail from "../../../../assets/images/icons/svg/sms.svg";
import userImg from "../../../../assets/images/icons/svg/user.svg";
import compicon from "../../../../assets/images/icons/svg/office.svg";
import PhoneInput from "react-phone-input-2";
import { setContactUsState, setMessageSnackbarStateState} from '../../../../redux/slices/common';
import AuthService from "../../../../services/api/auth.service";
import user from "../../../../assets/images/icons/svg/user.svg";
import UserService from "../../../../services/api/user.service";
import {setMessage} from "../../../../redux/slices/message";
import {} from "../../../../redux/slices/common";

function ContactUsModal() {
  const dispatch = useDispatch();
  const browserWidth = useWindowWidth();
  const { contactUsState } = useSelector((state) => state.common);
  const { selectedCountry } = useSelector((state) => state.auth);
  const { user } = useSelector((state) => state.auth.auth);
    const [isSuccess, setIsSuccess] = useState(false);
  const { currentTheme } = useSelector((state) => state.theme);
  const { register, handleSubmit, reset, formState: { errors } } = useForm({
    mode: 'onChange'
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [responseMessage, setResponseMessage] = useState(null);
  const [countryName, setCountryName] = useState('+1')


  const handleClose = () => {
      reset();
      dispatch(setContactUsState(false));
  };
  const [phone, setPhone] = useState("");



  const handlePhoneChange = (value, country, event, formattedValue) => {
    // Extract the country code
    // Update the phone and countryCode state variables
    const inputString = formattedValue;
    const spaceIndex = inputString.indexOf(' ');
    let phoneNo;
    if (spaceIndex !== -1) {
      const firstPart = inputString.slice(0, spaceIndex);
      const phoneNumberWithSpaces = inputString.slice(spaceIndex + 1);
      const phoneNumber = phoneNumberWithSpaces.replace(/\s+/g, ''); // Remove spaces from the phone number

      phoneNo = firstPart + "-" + phoneNumber;
    } else {
      console.log('No space found in the input string.');
    }
    setPhone(phoneNo);
  };

  const successCallback = (position) => {
    // console.log(position.coords.latitude);
    getCountryFromCoordinates(position.coords.latitude, position.coords.longitude);
  };

  const errorCallback = (error) => {
    console.log(error);
  };

  //navigator.geolocation.getCurrentPosition(successCallback, errorCallback);

  const getCountryFromCoordinates = (latitude, longitude) => {
    const mapboxAccessToken = 'pk.eyJ1IjoicmFmaXVsLW1lbW9iIiwiYSI6ImNsY3NoeGgwZDBmaTQzeHAxZ2JmcnlibTAifQ.l8EHVjWKI-Dur3R_tMBIPQ';

    fetch(`https://api.mapbox.com/geocoding/v5/mapbox.places/${longitude},${latitude}.json?access_token=${mapboxAccessToken}`)
        .then(response => response.json())
        .then(data => {
          console.log(data)
          const features = data.features;
          if (features.length > 0) {
            // The country name is often available in the 'country' or 'country_name' properties
            const countryName = features[6].properties.short_code
            console.log(`User's Country: ${countryName}`);
            setCountryName(countryName);
          } else {
            console.error('Country data not found.');
          }
        })
        .catch(error => {
          console.error('Error fetching country data:', error);
        });
  };



  const onSubmit = (values) => {

    function isEmailValid(email) {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      const blockedDomains = ["gmail.com", "yahoo.com", "outlook.com", "hotmail.com"];

      if (emailRegex.test(email)) {
        const emailDomain = email.split('@')[1];
        if (!blockedDomains.includes(emailDomain)) {
          return 'valid'; // Email is valid and not from a blocked domain
        } else {
          return 'blockedDomain'; // Email is from a blocked domain
        }
      }

      return 'invalidFormat'; // Email format is invalid
    }

    const emailValidationResult = isEmailValid(values.email);

      if (emailValidationResult === 'invalidFormat') {
          dispatch(setMessage({
              type: "error",
              title: "Invalid Email Address",
          }));
          dispatch(setMessageSnackbarStateState(true));
          return; // Stop further validation
      } else if (emailValidationResult === 'blockedDomain') {
          dispatch(setMessage({
              type: "error",
              title: "Please enter your business email address",
          }));
          dispatch(setMessageSnackbarStateState(true));
          return; // Stop further validation
      } else {
        // All validations passed, construct the queryParams object
            // All validations passed, construct the queryParams object
            const formData = {
                name: values.name,
                companyName: values.companyName,
                email: values.email,
                phoneNumber: phone,
                message: values.message,
                userId: user.id
            };
            // console.log("Form Data" + formData.confirmPassword)

            setIsSubmitting(true);
            UserService.contactUs(formData).then(
                (data) => {
                    if (data) {
                        setIsSubmitting(false);
                        reset();
                        handleClose();
                    }
                    return Promise.resolve();
                },
                (error) => {
                    setIsSubmitting(false);
                    console.log(error);
                    return Promise.reject();
                }
            );
        }
    };


  return (<>
    <Dialog
      open={contactUsState}
      onClose={handleClose}
      // onBackdropClick={handleBackdropClick}
      PaperProps={{ sx: { width: "400px", borderRadius: "0px" } }}
      sx={{
        backdropFilter: "blur(25px)",
      }}
    >
      <DialogTitle textAlign="center" sx={{ p: 3, fontSize: 16, lineHeight: '20px' }}>Contact Us</DialogTitle>
      <Box
        component='form'
        noValidate
        autoComplete='off'
        onSubmit={handleSubmit(onSubmit)}
      >
        <DialogContent sx={{ px: browserWidth > 500 ? 7.5 : 3 }}>
          <TextField
              autoFocus
              required
              fullWidth
              error={!!errors["name"]}
              helperText={errors["name"] ? errors["name"].message : ""}
              {...register("name", {
                required: "Name is required",
              })}
              sx={{ mb: 4 }}
              type="text"
              placeholder="Name"
              variant="standard"
              InputProps={{
                style: { paddingBottom: "12px", fontSize: "18px" },
                className: "form-control",
                startAdornment: (
                    <InputAdornment position="start" sx={{ ml: 1, mr: 2 }}>
                      <Image
                          src={userImg}
                          alt="mail"
                          style={{ width: 20, height: 20, objectFit: "contain" }}
                      />
                    </InputAdornment>
                ),
              }}
          />
          <TextField
              required
              fullWidth
              error={!!errors["companyName"]}
              helperText={
                errors["companyName"] ? errors["companyName"].message : ""
              }
              {...register("companyName", {
                  required: "Company name is required",
              })}
              sx={{ mb: 4 }}
              type="text"
              placeholder="Company Name"
              variant="standard"
              InputProps={{
                style: { paddingBottom: "12px", fontSize: "18px" },
                className: "form-control",
                startAdornment: (
                    <InputAdornment position="start" sx={{ ml: 1, mr: 2 }}>
                      <Image
                          src={compicon}
                          alt="mail"
                          style={{ width: 20, height: 20, objectFit: "contain" }}
                      />
                    </InputAdornment>
                ),
              }}
          />
          <TextField
              required
              fullWidth
              error={!!errors["email"]}
              helperText={errors["email"] ? errors["email"].message : ""}
              {...register("email", {
                required: "Email is required",
              })}
              sx={{ mb: 4 }}
              type="email"
              placeholder="Company Email"
              variant="standard"
              InputProps={{
                style: { paddingBottom: "12px", fontSize: "18px" },
                className: "form-control",
                startAdornment: (
                    <InputAdornment position="start" sx={{ ml: 1, mr: 2 }}>
                      <Image
                          src={mail}
                          alt="mail"
                          style={{ width: 20, height: 20, objectFit: "contain" }}
                      />
                    </InputAdornment>
                ),
              }}
          />
            <Box style={{ paddingBottom: "25px" }}>
                <TextField label="Query Description" id="outlined-size-normal" multiline rows={4} fullWidth
                           sx={{
                               '& .MuiOutlinedInput-root': {
                                   '& fieldset': {
                                       borderColor: currentTheme === 'dark' ?  'rgba(255,255,255,0.53)':'#000', // Default color
                                   },
                                   '&:hover fieldset': {
                                       borderColor: currentTheme === 'dark' ?  '#3C4DE7':'#000', // Hover color
                                   },
                                   '&.Mui-focused fieldset': {
                                       borderColor: currentTheme === 'dark' ?  '#3C4DE7':'#000', // Focused color
                                   },
                               },
                           }}
                           InputLabelProps={{
                               style: {
                                   color: currentTheme === 'dark' ? 'rgba(255,255,255,0.53)' : '#000', // Custom label color
                               },
                           }}
                           type="text"
                           required
                           helperText={errors["message"] ? errors["message"].message : ""}
                           {...register("message", {
                               required: "query description is required",
                           })}
                           error={!!errors["message"]}
                />
            </Box>
          <PhoneInput
              country={countryName}
              enableSearch={true}
              value={phone}
              onChange={handlePhoneChange}
              inputStyle={{
                // border:'none',
                // borderBottom: '1px solid grey', // You can adjust the color and style as needed
                borderRadius: '0',
                width: '100%',
                background: currentTheme === 'dark' ? '#010412' : '#FFF',
                color: currentTheme === 'dark' ?  '#FFF':'#000',
              }}
              buttonStyle={{ color: '#000' }}
          />
        </DialogContent>
        <Divider />
        <DialogActions sx={{ padding: "0px" }}>
          <ButtonGroup fullWidth size='large' aria-label="outlined primary button group">
            <Button variant='flat' onClick={handleClose} style={{ textTransform: "none", borderRadius: "0px" }}>Cancel</Button>
            <LoadingButton
              variant='contained'
              fullWidth
              type='submit'
              loading={isSubmitting}
              className="form-button"
              sx={{ textTransform: "none", borderRadius: "0px" }}
            >
              Ok
            </LoadingButton>
          </ButtonGroup>
        </DialogActions>
      </Box>
    </Dialog>
  </>);
}

export default ContactUsModal;