import React, { useState, useEffect, useRef } from "react";
import { Box, Button, ButtonGroup, Checkbox, Dialog, DialogActions, DialogContent, Divider, IconButton, InputAdornment, InputLabel, List, ListItem, ListItemText, Tab, TextField, Typography, Radio, RadioGroup, CircularProgress, Autocomplete } from "@mui/material";
import { useDispatch, useSelector } from 'react-redux';
import { setUploadAudienceModalState, setMessageSnackbarStateState } from '../../../../redux/slices/common';
import { LoadingButton, TabContext, TabList, TabPanel } from "@mui/lab";
// import { ListItemSecondaryAction } from "@mui/material";
import GetAppIcon from '@mui/icons-material/GetApp';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import WhereToVoteIcon from '@mui/icons-material/WhereToVote';
import { useForm } from "react-hook-form";
// import { styled, keyframes } from '@mui/system';
import FormControlLabel from "@mui/material/FormControlLabel";
import { setMessage } from '../../../../redux/slices/message';
import { errorMessage } from '../../../../config/config';
import {
  setDefaultSearchKeywords,
  setPoiIdsList,
  setCheckedUser,
  setCheckedSystem, setPartnerPOIList, setBrandIdList
} from "../../../../redux/slices/audience";
import PoiService from "../../../../services/api/poi.service";
import UploadPoiSuccessModal from "../upload-poi-success-modal/UploadPoiSuccessModal";
import { useNavigate } from "react-router-dom";
import { SearchStatus } from "iconsax-react";
import { imageUrl } from "../../../../config/config";
import { debounce } from "../../../../utils/utils";
import { getPermission } from "../../../../utils/cryptograpgy";

function UploadAudienceModal() {
  const downloadPOIPermission = getPermission('download_poi');
  const [isLibraryLoading, setIsLibraryLoading] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  // const [isEnd, setIsEnd] = useState();
  const [activeTab, setActiveTab] = useState('1');
  const [subTabValue, setSubTabValue] = useState('a');
  const [category, setCategory] = useState("");
  const [city, setCity] = useState("");
  // const [resultData, setResultData] = useState("");
  const [selectAllResult, setSelectAllResult] = useState("");
  const [poiMessage, setPoiMessage] = useState(0);
  // const [checkedUser, setCheckedUser] = useState([]);
  // const [checkedSystem, setCheckedSystem] = useState([]);
  const [uploadPoiSuccessModalState, setUploadPoiSuccessModalState] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState({ selectedCategory: "", selectedCity: "" });
  const [input, setInput] = useState('');
  const [tags, setTags] = useState([]);
  const [isKeyReleased, setIsKeyReleased] = useState(false);
  const { uploadAudienceModalState } = useSelector((state) => state.common);
  const { selectedCountry } = useSelector((state) => state.auth);
  const { audienceIdsList, poiIdsList, brandIdList } = useSelector((state) => state.audience);
  const { user } = useSelector((state) => state.auth.auth);
  const { checkedUser } = useSelector((state) => state.audience);
  const { checkedSystem } = useSelector((state) => state.audience);
  const { currentTheme } = useSelector((state) => state.theme);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  // const colors = ['RED', 'GREEN', 'YELLOW', 'BLUE', 'SKYBLUE'];
  const [poiPinColor, setPoiPinColor] = useState(null);
  const [selectedValue, setSelectedPoiPinColor] = useState(null);

  const [poiLibrary, setPoiLibrary] = useState(null);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const { register, handleSubmit, reset, formState: { errors } } = useForm({
    mode: 'onChange'
  });

  const { handleSubmit: handleSubmit2 } = useForm({
    mode: 'onChange'
  });

  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState(null);

  const [isValidTag, setIsValidTag] = React.useState(false);
  // const [pageNumber, setPageNumber] = useState(0);
  // const [pageSize, setPageSize] = useState(100);
  const [searchText, setSearchText] = useState('');
  const poiMissingInfo = 'POI is currently not available, kindly upload the location manually.';
  const systemPoiLibraryPermission = getPermission('system_poi');

  //   const fadeAnimation = keyframes`
  //   0% {
  //     opacity: 0;
  //   }
  //   50% {
  //     opacity: 1;
  //   }
  //   100% {
  //     opacity: 0;
  //   }
  // `;

  //   const GlowBox = styled(Box)(({ theme }) => ({
  //     position: 'relative',
  //     '&::after': {
  //       content: '""',
  //       position: 'absolute',
  //       left: '50%',
  //       bottom: '-4px',
  //       transform: 'translateX(-50%)',
  //       width: '180px',
  //       height: '10px',
  //       borderRadius: '50%',
  //       background: 'linear-gradient(to right, rgba(33, 150, 243, 0) 0%, rgba(33, 150, 243, 1) 50%, rgba(33, 150, 243, 0) 100%)',
  //       animation: `${fadeAnimation} 2s infinite`,
  //       zIndex: 1,
  //     },
  //   }));

  const poiDetails = async () => {
    try {
      if (selectedCountry) {
        const queryParams = [
          `countryId=${selectedCountry.id}`,
        ];
        const data = await PoiService.poiDetailsALLCategory(queryParams.join('&'));
        setIsLibraryLoading(false);
        if (data?.results?.length) {
          setCategory(data.results);
        }
      }
    } catch (error) {
      setIsLibraryLoading(false);
      console.log(error);
    }

    try {
      if (selectedCountry) {
        // const queryParams = [
        //   `countryId=${selectedCountry.id}`,
        // ];
        // const data = await PoiService.poiDetailsCites(queryParams.join('&'));
        // setIsLibraryLoading(false);
        // if (data?.results?.length) {
        //   setCity(data.results);
        // }
      }
    } catch (error) {
      setIsLibraryLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    poiDetails();
  }, [selectedCountry]);


  useEffect(() => {
    let mounted = true;
    if (mounted) {
      setActiveTab('1');
      setTags([]);
      setFileName(null);
      reset();
    }

    return () => {
      mounted = false;
    }
  }, [uploadAudienceModalState]);

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      PoiService.getPoiPincolor().then(
        (data) => {
          if (data?.results) {
            // console.log('getPoiPinColor', data.results[0]);
            setPoiPinColor(data.results.slice(0, 5));
            setSelectedPoiPinColor(JSON.stringify(data.results[0]));
          }
          return Promise.resolve();
        },
        (error) => {
          // console.log(error?.response?.data?.message);
          return Promise.reject();
        }
      );
    }

    return () => {
      mounted = false;
    }
  }, []);

  const handleClose = (e, reason) => {
    if (reason !== "backdropClick") {
      setSelectAll(false);
      // setPageNumber(0);
      dispatch(setCheckedUser([]));
      dispatch(setCheckedSystem([]));
      // setPageSize(100);
      dispatch(setUploadAudienceModalState(false));
      setPoiMessage("0");
    }
  };

  const handleBackdropClick = (event) => {
    event.stopPropagation();
    return false;
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
    setSelectAll(false);
    setSelectedOptions({ selectedCategory: "" });
    setPoiMessage('0');
    switch (newValue) {
      case '2':
        dispatch(setPartnerPOIList(''));
        loadPoiLibrary();
        break;
      case '3':
        loadPoiLibraryAll("3");
        dispatch(setCheckedUser([]));
        dispatch(setPoiIdsList(''));
        break;
      default:
        break;
    }
  };

  const handleSubTabChange = (event, newValue) => {
    setSelectAll(false);
    if (newValue === 'a') {
      setSelectedOptions({ selectedCategory: "", selectedCity: "" });
      setPoiMessage('0');
      dispatch(setPartnerPOIList(''));
    }
    setSubTabValue(newValue);
  };

  const onChangeOption = (newValue, type) => {
    setSelectedOptions(prevOptions => ({
      ...prevOptions,
      [type]: newValue
    }));
    onChangeFilter(newValue, type);
  };

  const handleToggleUser = (value) => () => {
    const currentIndex = checkedUser.indexOf(value);
    const newChecked = [...checkedUser];
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    // setCheckedUser(newChecked);
    dispatch(setCheckedUser(newChecked));
  }

  const handleToggleSystem = (value) => () => {
    const currentIndex = checkedSystem.indexOf(value);
    const newChecked = [...checkedSystem];
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    // setCheckedSystem(newChecked);
    dispatch(setCheckedSystem(newChecked));
  }

  const handleFileChange = event => {
    const fileObj = event.target.files && event.target.files[0];
    if (!fileObj) {
      return false;
    } else {
      const acceptedFileTypes = ['text/csv'];
      if (acceptedFileTypes.includes(fileObj.type)) {
        setFile(fileObj);
        setFileName(fileObj.name);
      } else {
        dispatch(setMessage({
          type: "error",
          title: "Only csv files are allowed",
        }));
        dispatch(setMessageSnackbarStateState(true));
      }
    }
  }

  const handleUploadPoiSuccessModalClose = () => {
    setActiveTab('2');
    loadPoiLibrary();
    setUploadPoiSuccessModalState(false);
  }

  const handleSearchText = (e) => {
    const searchText = e.target.value;
    // console.log("searchText", searchText);
    if (searchText.length >= 3) {
      loadPoiLibrary(searchText);
    }
    if (searchText === '') {
      loadPoiLibrary('');
    }
  }

  const handleSearchTextAll = (e) => {
    const searchTextAll = e.target.value;
    setSearchText(e.target.value);
    if (searchTextAll.length >= 3) {
      loadPoiLibraryAll(searchTextAll);
    }
    if (searchTextAll === '') {
      loadPoiLibraryAll('');
    }
  }

  const loadPoiLibrary = (searchText = '') => {
    if (user && selectedCountry) {
      setIsLibraryLoading(true);
      // setPageNumber(0);
      const queryParams = [
        `countryId=${selectedCountry.id}`,
        `userId=${user.id}`,
      ];
      if (poiIdsList) {
        // setCheckedUser(poiIdsList.split(',').map(Number));
        dispatch(setCheckedUser(poiIdsList.split(',').map(Number)));
        queryParams.push(`poiIds=${audienceIdsList}`);
      }
      // if (audienceIdsList) {
      //   queryParams.push(`audienceIds=${audienceIdsList}`);
      // }
      if (searchText) {
        queryParams.push(`searchText=${searchText}`);
      }
      PoiService.library(queryParams.join('&')).then(
        (data) => {
          setIsLibraryLoading(false);
          if (data?.results) {
            // console.log("library", data.results);
            setPoiLibrary(data.results);
            dispatch(setCheckedSystem([]));
            // setPageNumber(0);
            // setPageSize(100);
            // const checkedIds = [];
            // for (let index = 0; index < data.results.length; index++) {
            //   const element = data.results[index];
            //   if (element?.isSelect) {
            //     checkedIds.push(index);
            //   }
            // }
            // if (checkedIds.length) {
            //   setChecked(checkedIds);
            // } else {
            //   setChecked([0]);
            // }
          } else {
            setPoiLibrary(null);
          }
          return Promise.resolve();
        },
        (error) => {
          // console.log(error?.response?.data?.message);
          setIsLibraryLoading(false);
          return Promise.reject();
        }
      );
    }
  }

  const containerRef = useRef(null);
  const loadPoiLibraryAll = (searchText) => {
    const container = containerRef.current;
    if (user && selectedCountry) {
      if (!container) {
        setIsLibraryLoading(true);
        // setPageNumber(0);
      }
      // setPageSize(100);
      let queryParams = [
        `countryId=${selectedCountry.id}`,
        `userId=${user.id}`,
        // `pageNumber=${pageNumber}`,
        // `pageSize=${'100'}`
      ];
      // if (!searchText === "3" || searchText === "") {
      //   setPageNumber(prevPageNumber => prevPageNumber + 1);
      // }
      // setPageSize(prevPageSize => prevPageSize + 100);
      if (brandIdList) {
        // setCheckedSystem(poiIdsList.split(',').map(Number));
        dispatch(setCheckedSystem(brandIdList.split(',').map(Number)));
      }
      if (searchText !== "3" && searchText !== "") {
        //queryParams = queryParams.filter(param => !param.startsWith('pageNumber='));
        queryParams.push(`searchText=${searchText}`);
        //queryParams.push(`pageNumber=${'0'}`);
      }
      // console.log(queryParams)
      PoiService.libraryAll(queryParams.join('&')).then(
        (data) => {
          setIsLibraryLoading(false);
          if (data?.results) {
            // setIsEnd(data.results.isEnd);
            // console.log("library", data.results);
            setPoiLibrary(data.results.poiDtoList);
            dispatch(setCheckedUser([]));
            // const checkedIds = [];
            // for (let index = 0; index < data.results.length; index++) {
            //   const element = data.results[index];
            //   if (element?.isSelect) {
            //     checkedIds.push(index);
            //   }
            // }
            // if (checkedIds.length) {
            //   setChecked(checkedIds);
            // } else {
            //   setChecked([0]);
            // }
          } else {
            setPoiLibrary(null);
          }
          return Promise.resolve();
        },
        (error) => {
          // console.log(error?.response?.data?.message);
          setIsLibraryLoading(false);
          return Promise.reject();
        }
      );
    }
  }

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      onSubmit();
    }
  }

  const onSubmit = (values) => {
    // console.log("values", values);
    // console.log("filename", fileName);
    // const formattedTags = tags.map((tag) => validateCase(tag));
    const poiPinColorValue = JSON.parse(values.poiPinColor);
    if (user && selectedCountry) {
      const queryParams = [
        `placeName=${values.placeName.trim()}`,
        `tags=${tags}`,
        `userId=${user.id}`,
        `countryId=${selectedCountry.id}`,
        `pinColor=${poiPinColorValue.colorName}`,
        `colorValue=${poiPinColorValue.colorValue}`,
      ];
      // return false;
      const formData = new FormData();
      formData.append('file', file);
      // formData.append('pinColor', 'selectedValue');
      setIsSubmitting(true);
      PoiService.addPoi(queryParams.join('&'), formData).then(
        (data) => {
          // console.log("data", data);
          setIsSubmitting(false);
          if (data?.results) {
            setUploadPoiSuccessModalState(true);
            reset();
            setInput('');
            setTags([]);
            setIsValidTag(false);
            setFileName(null);
          } else {
            dispatch(setMessage({
              type: "error",
              title: errorMessage,
            }));
            dispatch(setMessageSnackbarStateState(true));
            setIsSubmitting(false);
          }
          return Promise.resolve();
        },
        (error) => {
          console.log(error);
          setIsSubmitting(false);
          dispatch(setMessage({
            type: "error",
            title: error.response.data.message,
          }));
          dispatch(setMessageSnackbarStateState(true));
          setIsSubmitting(false);
          return Promise.reject();
        }
      );
    }
  };

  const onSubmitPoiLoad = (val, values, event) => {
    setSearchText("");
    // setPageNumber(0);
    // console.log(values)
    let poiIdsList = [];
    let brandIdList = [];
    if (checkedUser.length > 0) {

      poiIdsList = checkedUser;
      dispatch(setBrandIdList(''));
    } else if (val === 'brand' && checkedSystem.length > 0) {

      brandIdList = checkedSystem;
      dispatch(setPartnerPOIList(''));
    } else if (val === 'category' && selectedOptions && (selectedOptions.selectedCategory !== '' || selectedOptions.selectedCity !== '') && poiMessage !== 0) {

      const newFilters = [
        {
          categoryName: selectedOptions.selectedCategory !== "" && selectedOptions.selectedCategory !== null ? selectedOptions.selectedCategory.id : "",
          // cityName: selectedOptions.selectedCity !== "" && selectedOptions.selectedCity !== null ? selectedOptions.selectedCity.cityName : ""
        }
      ];
      dispatch(setPartnerPOIList(newFilters));
      setPoiMessage("0");
      setSelectedOptions({ selectedCategory: "", selectedCity: "" });
      dispatch(setPoiIdsList(''));
      dispatch(setCheckedUser([]));
      dispatch(setCheckedSystem([]));
      dispatch(setBrandIdList(''));
    } else {
      dispatch(setMessage({
        type: "error",
        title: "Select a POI",
      }));
      dispatch(setMessageSnackbarStateState(true));
      return;
    }

    setIsSubmitting(true);
    dispatch(setDefaultSearchKeywords([]));
    dispatch(setUploadAudienceModalState(false));

    if ((val !== 'brand' && val !== 'category') && poiIdsList) {
      dispatch(setPoiIdsList(poiIdsList.join(',')));
    } else {
      dispatch(setPoiIdsList(''));
    }

    if (val === 'brand' && brandIdList) {
      dispatch(setBrandIdList(brandIdList.join(',')));
    } else {
      dispatch(setBrandIdList(''));
    }


    navigate(`/search`);
    setIsSubmitting(false)
  }

  const poiDownload = (item) => {
    const queryParams = [
      `userId=${user.id}`,
      `countryId=${selectedCountry.id}`,
      `poiId=${item.id}`
    ]
    PoiService.poiDownload(queryParams.join('&')).then(
      (data) => {
        const url = URL.createObjectURL(new Blob([data], { type: "text/csv" }));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${item.placeName}`);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      }).catch((error) => {
        console.log(error)
        dispatch(setMessage({
          type: "error",
          title: error.response.data.message,
        }));
        dispatch(setMessageSnackbarStateState(true));
      });
  }


  const brandPOIDownload = (item) => {
    const queryParams = [
      `userId=${user.id}`,
      `countryId=${selectedCountry.id}`,
      `brandId=${item.id}`
    ]
    PoiService.brandPOIDownload(queryParams.join('&')).then(
      (data) => {
        const url = URL.createObjectURL(new Blob([data], { type: "text/csv" }));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${item.placeName}`);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      }).catch((error) => {
        console.log(error)
        dispatch(setMessage({
          type: "error",
          title: error.response.data.message,
        }));
        dispatch(setMessageSnackbarStateState(true));
      });
  }

  const validateCase = (tagName) => {
    const pattern = /^[a-zA-Z0-9]*$/;
    return pattern.test(tagName);
  };

  const onChange = (e) => {
    const { value } = e.target;
    setInput(value);
    setIsValidTag(validateCase(value));
  };

  const onChangeFilter = (newValue, type) => {
    setSelectAll(false);
    setIsLibraryLoading(true);

    const queryParams = [
      `countryId=${selectedCountry.id}`
    ];

    if (type === 'selectedCategory' && newValue && newValue.id) {
      queryParams.push(`category=${newValue.id}`);
    } else if (type !== 'selectedCategory' && selectedOptions.selectedCategory?.id) {
      queryParams.push(`category=${selectedOptions.selectedCategory.id}`);
    }

    // if (type === 'selectedCity' && newValue && newValue.cityName) {
    //   queryParams.push(`city=${newValue.cityName}`);
    // } else if (type !== 'selectedCity' && selectedOptions.selectedCity?.cityName) {
    //   queryParams.push(`city=${selectedOptions.selectedCity.cityName}`);
    // }

    // const hasCity = queryParams.some(param => param.startsWith('city='));
    // const hasCategory = queryParams.some(param => param.startsWith('category='));

    queryParams.push('pageNumber=0', 'pageSize=1000');


    PoiService.poiDetails(queryParams.join('&')).then(
      (data) => {
        setIsLibraryLoading(false);
        if (data) {
          setSelectAllResult(data.results);
          // setResultData(data.results.splice(0, 100));
          setPoiMessage(data.results.poiCount);
        }
        return Promise.resolve();
      },
      (error) => {
        setIsLibraryLoading(false);
        return Promise.reject();
      }
    )

  };

  const onKeyDown = (e) => {
    const { key } = e;
    const trimmedInput = input.trim();

    if (isValidTag) {
      if (key === ',' || key === ' ' || key === 'Enter') {
        e.preventDefault();
        if (trimmedInput.length && !tags.includes(trimmedInput)) {
          setTags((prevState) => [...prevState, trimmedInput]);
        }
        setInput('');
      } else if (key === 'Backspace' && !input.length && tags.length && isKeyReleased) {
        const tagsCopy = [...tags];
        const poppedTag = tagsCopy.pop();
        e.preventDefault();
        setTags(tagsCopy);
        setInput(poppedTag);
      }

      setIsKeyReleased(false);
    };
  }

  const onKeyUp = () => {
    setIsKeyReleased(true);
  }

  const handleTagClick = (tag) => {
    const updatedTags = tags.filter((t) => t !== tag);
    setTags(updatedTags);
  };

  const handleBlur = () => {
    if (
      input.trim().length > 0 &&
      !tags.includes(input.trim()) &&
      /^[a-z]+$/.test(input.trim())
    ) {
      setTags((prevState) => [...prevState, input.trim()]);
    }
    setInput('');
  };

  // const poiLibraryRef = useRef(null);
  // const handleScroll = () => {
  //   const thresold = 0;
  //   const container = containerRef.current;
  //   if (container.scrollTop + container.clientHeight >= container.scrollHeight - thresold) {
  //     container.scrollTo(0, container.scrollTop + 750);
  //     loadPoiLibraryAll(searchText);
  //   }
  // if (container.scrollTop === 0) {
  //   const queryParams = [
  //     `countryId=${selectedCountry.id}`,
  //     `userId=${user.id}`,
  //     `pageNumber=${pageNumber}`,
  //     `pageSize=${'100'}`
  //   ];
  //   setPageNumber(prevPageNumber => Math.max(prevPageNumber - 1, 0));
  //   if (pageNumber !== 0) {
  //     container.scrollTo(0, container.scrollTop + 250);
  //   }
  //   // setPageSize(prevPageSize => prevPageSize + 100);
  //   if (poiIdsList) {
  //     queryParams.push(`poiIds=${audienceIdsList}`);
  //   }
  //   PoiService.libraryAll(queryParams.join('&')).then(
  //     (data) => {
  //       setIsLibraryLoading(false);
  //       if (data?.results?.length) {
  //         // console.log("library", data.results);
  //         setPoiLibrary(data.results);
  //       } 
  //       return Promise.resolve();
  //     },
  //     (error) => {
  //       // console.log(error?.response?.data?.message);
  //       setIsLibraryLoading(false);
  //       return Promise.reject();
  //     }
  //   );
  // }
  // const thresold = 250;
  // if (container.scrollTop + container.clientHeight >= container.scrollHeight) {
  //   // loadPoiLibraryAll();
  //   const queryParams = [
  //     `countryId=${selectedCountry.id}`,
  //     `userId=${user.id}`,
  //     `pageNumber=${'0'}`,
  //     `pageSize=${pageSize}`
  //   ];
  //   // setPageNumber(prevPageNumber => prevPageNumber + 1);
  //   // container.scrollTo(0, container.scrollTop - 250);
  //   setPageSize(prevPageSize => prevPageSize + 100);
  //   if (poiIdsList) {
  //     queryParams.push(`poiIds=${audienceIdsList}`);
  //   }
  //   PoiService.libraryAll(queryParams.join('&')).then(
  //     (data) => {
  //       setIsLibraryLoading(false);
  //       if (data?.results) {
  //         setPoiLibrary(data.results.poiDtoList);
  //         // poiLibraryRef.current = data.results;
  //       }
  //       return Promise.resolve();
  //     },
  //     (error) => {
  //       // console.log(error?.response?.data?.message);
  //       setIsLibraryLoading(false);
  //       return Promise.reject();
  //     }
  //   );
  // }
  // };

  const handleCategorySearch = async (e) => {
    const searchText = e;  // Changed variable name to match the condition

    const queryParams = [
      `countryId=${selectedCountry.id}`,
    ];

    // Push searchText to queryParams only if its length is greater than 3
    if (searchText.length > 1) {
      queryParams.push(`searchText=${searchText}`);

      const data = await PoiService.poiDetailsALLCategory(queryParams.join('&'));
      setIsLibraryLoading(false);
      if (data?.results?.length) {
        setCategory(data.results);
      }
    }

    if(searchText === '') {
      const data = await PoiService.poiDetailsALLCategory(queryParams.join('&'));
      setIsLibraryLoading(false);
      if (data?.results?.length) {
        setCategory(data.results);
      }
    }
  }

  return (<>
    <Dialog
      open={uploadAudienceModalState}
      onClose={handleClose}
      onBackdropClick={handleBackdropClick}
      PaperProps={{ sx: { width: "420px", borderRadius: "0px" } }}
      sx={{
        backdropFilter: "blur(25px)",
      }}
      className="UploadAudienceModalDialog"
    >
      <TabContext value={activeTab}>

        <TabList variant='fullWidth' onChange={handleTabChange} aria-label="Audience Tabs" className="TabListArea" sx={{ position: 'relative' }}>
          <Tab label="Upload" value="1" sx={{ py: 3, fontsize: 14, lineHeight: '17px', fontWeight: activeTab === '1' ? '700' : '400', textTransform: 'none' }} className="TabListButton" />
          <Tab label="User Library" value="2" sx={{ py: 3, fontsize: 14, lineHeight: '17px', fontWeight: activeTab === '2' ? '700' : '400', textTransform: 'none' }} className="TabListButton" />
          {systemPoiLibraryPermission &&
            <Tab label="System Library" value="3" sx={{ py: 3, fontsize: 14, lineHeight: '17px', fontWeight: activeTab === '2' ? '700' : '400', textTransform: 'none' }} className="TabListButton" />
          }
        </TabList>

        <Divider />

        <TabPanel value="1" sx={{ padding: "0px" }}>
          <Box
            component='form'
            noValidate
            autoComplete='off'
            onSubmit={handleSubmit(onSubmit)}
            onBackdropClick={handleBackdropClick}
          >
            <DialogContent>
              <TextField
                autoFocus
                InputProps={{
                  style: { padding: "0 8px 10px 8px", fontSize: "14px" },
                }}
                placeholder="Place Name (E.g. My NYC Stores)"
                InputLabelProps={{
                  style: { color: "#6F7288" }
                }}
                id="upload-place-name"
                type="text"
                variant="standard"
                fullWidth
                sx={{ my: 1.5 }}
                error={!!errors['placeName']}
                helperText={errors['placeName'] ? errors['placeName'].message : ''}
                {...register("placeName", {
                  required: "Place Name is required"
                })}
              />
              {/* <TextField
                InputProps={{
                  style: { padding: "0 8px 10px 8px", fontSize: "10px" },
                }}
                placeholder="Places Categories (E.g. Coffee Shops)"
                InputLabelProps={{
                  style: { color: "#6F7288" }
                }}
                id="upload-place-category"
                type="text"
                variant="standard"
                fullWidth
                sx={{ my: 1.5 }}
                error={!!errors['category']}
                helperText={errors['category'] ? errors['category'].message : ''}
                {...register("category", {
                  required: "Category is required"
                })}
              /> */}
              <div>
                {tags.map((tag) => {
                  // const formattedTags = validateCase(tag);
                  return (
                    <div
                      key={tag}
                      style={{
                        display: 'inline-flex',
                        alignItems: 'center',
                        margin: '7px 0',
                        marginRight: '10px',
                        padding: '0 10px',
                        paddingRight: '5px',
                        border: '1px solid orange',
                        borderRadius: '5px',
                        backgroundColor: 'orange',
                        whiteSpace: 'nowrap',
                        color: 'white',
                      }}
                    >
                      {tag}
                      <button
                        style={{
                          display: 'flex',
                          padding: '6px',
                          border: 'none',
                          backgroundColor: 'unset',
                          cursor: 'pointer',
                          color: 'white',
                        }}
                        onClick={() => handleTagClick(tag)}
                      >
                        x
                      </button>
                    </div>
                  );
                })}
                <TextField
                  InputProps={{
                    style: { padding: "0 8px 10px 8px", fontSize: "14px" },
                  }}
                  InputLabelProps={{
                    style: { color: "#6F7288" }
                  }}
                  id="tags"
                  type="text"
                  variant="standard"
                  fullWidth
                  sx={{ my: 1.5 }}
                  value={input}
                  placeholder="Enter tags"
                  onKeyDown={onKeyDown}
                  onKeyUp={onKeyUp}
                  onChange={onChange}
                  onBlur={handleBlur}
                />
                {/* {isLowerCase && <p style={{ color: 'green' }}>Valid Tag entered.</p>} */}
                {!isValidTag && input && <p style={{ color: 'red' }} >Special characters are not allowed</p>}
              </div>
              <Button
                className="UploadProfilePhoto"
                sx={{ px: 2.5, py: 2, my: 2, textTransform: "none", borderRadius: "0px", minHeight: '70px' }}
                variant="outlined"
                fullWidth
                startIcon={<AddCircleOutlineIcon />}
              >
                <Box sx={{ fontSize: 10, textAlign: 'left', ml: 2 }}>
                  {/* <Box>Click to browse and upload file (or) tab delimited file (.TXT)</Box> */}
                  {/* <Box sx={{ fontSize: 10 }}>Max records: <Box sx={{ display: 'inline-flex', color: '#1B7FF4' }}>10,000</Box></Box> */}
                  <Box>{fileName ? fileName : "Click to browse and upload file (or) tab delimited file (.CSV) Max records:10, 000"}</Box>
                  {/* <Box>{fileName ? fileName : "Click to browse and upload file (or) tab delimited file (.TXT) Max records:" + `<span style='color: #1B7FF4'>${10, 000}</span>`}</Box> */}
                </Box>
                <input
                  style={{ zIndex: 2, display: 'block', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: '100%', height: '100%', opacity: 0, cursor: 'pointer' }}
                  type="file"
                  onChange={handleFileChange}
                  required
                />
              </Button>
              <Typography align='center'>
                {/* <Link
                  component="button"
                  variant="body1"
                  underline="hover"
                  sx={{ color: "#3955BF", margin: "10px auto", fontSize: 12, textAlign: 'center' }}
                  href="http://api-pics.memob.com/sample/sampledata.csv"
                  download="sampledata.csv">
                  Download Sample CSV File
                </Link> */}

                <a
                  href={imageUrl + "assets/sample/sampledata.csv"}
                  download="sampledata.csv">
                  Download Sample CSV File
                </a>
                {/* <Link
                  component="button"
                  variant="body1"
                  underline="hover"
                  sx={{ color: "#3955BF", margin: "10px auto", fontSize: 12, textAlign: 'center' }}>
                  View Sample TXT File
                </Link> */}
              </Typography>
            </DialogContent>

            <Divider variant="fullWidth" />
            {poiPinColor?.length &&
              <DialogContent sx={{ px: 2 }}>
                <InputLabel sx={{ color: "#6F7288", textAlign: "center", marginBottom: 1, fontSize: 10 }}>Pick your pin color</InputLabel>
                <Box>
                  <RadioGroup defaultValue={selectedValue} sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center', padding: 0 }}
                  >
                    {poiPinColor.map((color, index) => (
                      <Radio
                        key={index}
                        color="default"
                        value={JSON.stringify(color)}
                        icon={<LocationOnIcon sx={{ color: color.colorValue }} />}
                        checkedIcon={<WhereToVoteIcon sx={{ color: color.colorValue }} />}
                        {...register("poiPinColor")}
                      />
                    ))}
                  </RadioGroup>
                </Box>
              </DialogContent>
            }
            <Divider />
            <DialogActions sx={{ padding: "0px" }}>
              <ButtonGroup fullWidth size='large' aria-label="outlined primary button group">
                <Button variant='flat' onClick={handleClose} style={{ textTransform: "none", borderRadius: "0px" }}>Cancel</Button>
                <LoadingButton
                  variant='contained'
                  fullWidth
                  type='submit'
                  loading={isSubmitting}
                  className="form-button"
                  sx={{ textTransform: "none", borderRadius: "0px" }}
                >
                  Save
                </LoadingButton>
              </ButtonGroup>
            </DialogActions>
          </Box>
        </TabPanel>

        <TabPanel value="2" sx={{ padding: "0px" }}>
          <Box
            component='form'
            noValidate
            autoComplete='off'
            onSubmit={handleSubmit2((values, event) => onSubmitPoiLoad('user', values, event))}
          >
            <DialogContent sx={{ mx: 0, px: 4 }}>
              <TextField
                autoFocus
                InputProps={{
                  style: {
                    paddingBottom: "12px",
                  },
                  startAdornment: (
                    <InputAdornment position="start">
                      <Box sx={{ px: 2, display: 'flex', alignItems: 'center', paddingBottom: '12px' }}>
                        <SearchStatus className={`ImageIcon`} color="grey" />
                      </Box>
                    </InputAdornment>
                  ),
                }}
                placeholder="Search your place"
                InputLabelProps={{
                  style: { color: "#6F7288", paddingBottom: '12px' },
                }}
                focused
                id="standard-multiline-static-11"
                type="search"
                variant="standard"
                fullWidth
                sx={{ my: 2 }}
                onChange={debounce((e) => handleSearchText(e), 500)}
                onKeyDown={(e) => handleKeyDown(e)}
              />
              {isLibraryLoading ?
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 150 }}>
                  <CircularProgress />
                </Box>
                :
                <Box
                  sx={{
                    maxHeight: '400px',
                    overflow: 'auto',
                    '&::-webkit-scrollbar': {
                      width: '8px',
                    },
                    '&::-webkit-scrollbar-track': {
                      background: '#f1f1f1',
                    },
                    '&::-webkit-scrollbar-thumb': {
                      background: '#888',
                      borderRadius: '4px',
                    },
                    '&::-webkit-scrollbar-thumb:hover': {
                      background: '#555',
                    },
                  }}
                >
                  {poiLibrary?.length > 0 ?
                    <List>
                      {poiLibrary.map((item, index) => (
                        <ListItem key={item.id} disablePadding sx={{ justifyContent: 'space-between' }}>
                          <Box sx={{ display: 'flex', alignItems: 'start' }}>
                            <FormControlLabel
                              sx={{ ml: '2px' }}
                              onClick={handleToggleUser(item.id)}
                              className="form-control"
                              control={<Checkbox
                                edge="start"
                                checked={checkedUser.includes(item.id)}
                                tabIndex={-1}
                                disableRipple
                                inputProps={{ 'aria-labelledby': 'labelId' + index }}
                              />}
                            />
                            <ListItemText
                              id={'labelId' + index}
                              primary={item.placeName}
                              secondary={
                                <Typography color={'#6F7288'} sx={{ wordBreak: 'break-all' }}>
                                  {item.poiCount + " Places"}
                                </Typography>
                              }
                              sx={{ p: 0 }}
                            />
                          </Box>
                          <Box sx={{ ml: 2, display: 'flex', alignItems: 'center' }}>
                            <IconButton aria-label="comments">
                              <LocationOnIcon sx={{ color: item.colorValue, cursor: 'default' }} />
                            </IconButton>
                            {downloadPOIPermission &&
                              <IconButton aria-label="download" onClick={() => poiDownload(item)}>
                                <GetAppIcon sx={{ color: item.colorValue }} />
                              </IconButton>
                            }
                          </Box>
                        </ListItem>
                      ))}
                    </List> :
                    poiMissingInfo
                  }
                </Box>
              }
            </DialogContent>
            <Divider />
            <DialogActions sx={{ padding: "0px" }}>
              <ButtonGroup fullWidth size='large' aria-label="outlined primary button group">
                <Button variant='flat' onClick={handleClose} sx={{ textTransform: "none", borderRadius: "0px" }}>Cancel</Button>
                <LoadingButton
                  variant='contained'
                  fullWidth
                  type='submit'
                  loading={isSubmitting}
                  className="form-button"
                  sx={{ textTransform: "none", borderRadius: "0px" }}
                >
                  Load
                </LoadingButton>
              </ButtonGroup>
            </DialogActions>
          </Box>
        </TabPanel>

        <TabPanel value="3" sx={{ padding: "0px" }}>
          <TabContext value={subTabValue}>
            <TabList variant='fullWidth' onChange={handleSubTabChange} aria-label="Audience Tabs" className="TabListArea" sx={{ position: 'relative' }}>
              <Tab label="Brand" value="a" sx={{ py: 3, fontsize: 14, lineHeight: '17px', fontWeight: activeTab === 'a' ? '700' : '400', textTransform: 'none' }} className="TabListButton" />
              <Tab label="Categories" value="b" sx={{ py: 3, fontsize: 14, lineHeight: '17px', fontWeight: activeTab === 'b' ? '700' : '400', textTransform: 'none' }} className="TabListButton" />
            </TabList>
            <Divider />
            <TabPanel value="a" sx={{ padding: "0px" }}>
              <Box
                component='form'
                noValidate
                autoComplete='off'
                onSubmit={handleSubmit2((values, event) => onSubmitPoiLoad('brand', values, event))}
              >
                <DialogContent sx={{ mx: 0, px: 4 }}>
                  <TextField
                    autoFocus
                    InputProps={{
                      style: {
                        paddingBottom: "12px",
                      },
                      startAdornment: (
                        <InputAdornment position="start">
                          <Box sx={{ px: 0, display: 'flex', alignItems: 'center', paddingBottom: '12px' }}>
                            <SearchStatus className={`ImageIcon`} color="grey" />
                          </Box>
                        </InputAdornment>
                      ),
                    }}
                    placeholder="Search your place"
                    InputLabelProps={{
                      style: { color: "#6F7288", paddingBottom: '12px' },
                    }}
                    focused
                    id="standard-multiline-static-11"
                    type="search"
                    variant="standard"
                    fullWidth
                    // sx={{ mb: 2 }}
                    sx={{ my: 2 }}
                    onChange={debounce((e) => handleSearchTextAll(e), 500)}
                  />
                  {isLibraryLoading ?
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 150 }}>
                      <CircularProgress />
                    </Box>
                    :
                    <Box
                      ref={containerRef}
                      sx={{
                        maxHeight: '400px',
                        overflow: 'auto',
                        '&::-webkit-scrollbar': {
                          width: '8px',
                        },
                        '&::-webkit-scrollbar-track': {
                          background: '#f1f1f1',
                        },
                        '&::-webkit-scrollbar-thumb': {
                          background: '#888',
                          borderRadius: '4px',
                        },
                        '&::-webkit-scrollbar-thumb:hover': {
                          background: '#555',
                        },
                      }}
                    // onScroll={handleScroll}
                    >
                      {poiLibrary?.length > 0 ?
                        <List>
                          {poiLibrary.map((item, index) => (
                            <ListItem key={item.id} disablePadding sx={{ justifyContent: 'space-between' }}>
                              <Box sx={{ display: 'flex', alignItems: 'start' }}>
                                <FormControlLabel
                                  sx={{ ml: '2px' }}
                                  onClick={handleToggleSystem(item.id)}
                                  className="form-control"
                                  control={<Checkbox
                                    edge="start"
                                    checked={checkedSystem.includes(item.id)}
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{ 'aria-labelledby': 'labelId' + index }}
                                  />}
                                />
                                <ListItemText
                                  id={'labelId' + index}
                                  primary={item.placeName}
                                  secondary={
                                    <Typography color={'#6F7288'} sx={{ wordBreak: 'break-all' }}>
                                      {item.poiCount + " Places"}
                                    </Typography>
                                  }
                                  sx={{ p: 0 }}
                                />
                              </Box>
                              <Box sx={{ ml: 2, display: 'flex', alignItems: 'center' , marginRight: '25px'}}>
                                {/*<IconButton aria-label="comments">*/}
                                {/*  <LocationOnIcon sx={{ color: item.colorValue, cursor: 'default' }} />*/}
                                {/*</IconButton>*/}
                                {downloadPOIPermission &&
                                  <IconButton aria-label="download" onClick={() => brandPOIDownload(item)}>
                                    <GetAppIcon sx={{ color: item.colorValue }} />
                                  </IconButton>
                                }
                              </Box>
                            </ListItem>
                          ))}
                        </List> :
                        poiMissingInfo
                      }
                      {/* {isEnd && <GlowBox />} */}
                    </Box>
                  }
                </DialogContent>
                <Divider />
                <DialogActions sx={{ padding: "0px" }}>
                  <ButtonGroup fullWidth size='large' aria-label="outlined primary button group">
                    <Button variant='flat' onClick={handleClose} sx={{ textTransform: "none", borderRadius: "0px" }}>Cancel</Button>
                    <LoadingButton
                      variant='contained'
                      fullWidth
                      type='submit'
                      loading={isSubmitting}
                      className="form-button"
                      sx={{ textTransform: "none", borderRadius: "0px" }}
                    >
                      Load
                    </LoadingButton>
                  </ButtonGroup>
                </DialogActions>
              </Box>
            </TabPanel>
            <TabPanel value="b" sx={{ padding: "0px" }}>
              <Box
                component='form'
                noValidate
                autoComplete='off'
                onSubmit={handleSubmit2((values, event) => onSubmitPoiLoad('category', values, event))}
              >
                <DialogContent sx={{ mx: 0, px: 4 }}>
                  <Autocomplete
                    fullWidth
                    options={category || []}
                    value={selectedOptions.selectedCategory || null}
                    getOptionLabel={(option) => option.name}
                    onChange={(event, newValue) => {

                      if(newValue === null) {
                        poiDetails();
                      } else {
                        onChangeOption(newValue, 'selectedCategory');
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                          {...params}
                          placeholder={"Select a Category"}
                          variant="standard"
                          sx={{
                            my: 2,
                            '& .MuiChip-root': {
                              backgroundColor: currentTheme === 'light' ? '#3f3e3e':'#ededed' ,
                              color: currentTheme === 'light' ? '#000' : '#fff'
                            },
                          }}
                          {...register("category")}
                          // disabled={category && category.length === 0}
                          onChange={async (e) => {
                            const searchText = e.target.value;
                            // Await the handleCategorySearch function call
                            await handleCategorySearch(searchText);
                          }}
                      />

                    )}
                    paperprops={{
                      style: {
                        maxHeight: '50vh',
                        maxWidth: '50vh',
                        marginTop: '2px',
                        overflowX: 'auto',
                      },
                    }}
                  />

                  {/*<Autocomplete*/}
                  {/*  fullWidth*/}
                  {/*  options={city || []}*/}
                  {/*  value={selectedOptions.selectedCity || null}*/}
                  {/*  getOptionLabel={(option) => option.cityName}*/}
                  {/*  onChange={(event, newValue) => {*/}
                  {/*    onChangeOption(newValue, 'selectedCity');*/}
                  {/*  }}*/}
                  {/*  renderInput={(params) => (*/}
                  {/*    <TextField*/}
                  {/*      {...params}*/}
                  {/*      placeholder="Select a city"*/}
                  {/*      variant="standard"*/}
                  {/*      sx={{ my: 2, color: '#fff' }}*/}
                  {/*      {...register("city")}*/}
                  {/*      disabled={city && city.length === 0}*/}
                  {/*    />*/}
                  {/*  )}*/}
                  {/*  paperprops={{*/}
                  {/*    style: {*/}
                  {/*      maxHeight: '50vh',*/}
                  {/*      maxWidth: '50vh',*/}
                  {/*      marginTop: '2px',*/}
                  {/*      overflowX: 'auto',*/}
                  {/*    },*/}
                  {/*  }}*/}
                  {/*/>*/}

                  {isLibraryLoading ?
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 150 }}>
                      <CircularProgress />
                    </Box>
                    :
                    <Box
                      sx={{
                        maxHeight: '290px',
                        overflow: 'auto',
                        '&::-webkit-scrollbar': {
                          width: '8px',
                        },
                        '&::-webkit-scrollbar-track': {
                          background: '#f1f1f1',
                        },
                        '&::-webkit-scrollbar-thumb': {
                          background: '#888',
                          borderRadius: '4px',
                        },
                        '&::-webkit-scrollbar-thumb:hover': {
                          background: '#555',
                        },
                        display: 'flex',
                        justifyContent: 'center',
                        padding: 1
                      }}
                    >
                      {poiMessage !== undefined &&
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          <LocationOnIcon sx={{ color: '#3c4de7' }} />
                          {poiMessage}
                        </Box>
                      }
                    </Box>
                  }
                </DialogContent>
                <Divider />
                <DialogActions sx={{ padding: "0px" }}>
                  <ButtonGroup fullWidth size='large' aria-label="outlined primary button group">
                    <Button variant='flat' onClick={handleClose} sx={{ textTransform: "none", borderRadius: "0px" }}>Cancel</Button>
                    <LoadingButton
                      variant='contained'
                      fullWidth
                      type='submit'
                      loading={isSubmitting}
                      className="form-button"
                      sx={{ textTransform: "none", borderRadius: "0px" }}
                    >
                      Load
                    </LoadingButton>
                  </ButtonGroup>
                </DialogActions>
              </Box>
            </TabPanel>
          </TabContext>
        </TabPanel>
      </TabContext >
    </Dialog >

    <UploadPoiSuccessModal
      uploadPoiSuccessModalState={uploadPoiSuccessModalState}
      handleUploadPoiSuccessModalClose={handleUploadPoiSuccessModalClose}
    />
  </>);
}

export default UploadAudienceModal;