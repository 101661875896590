import React from 'react';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import Logo from '../../../../assets/images/Vector.png';
import { DialogContentText } from '@mui/material';
import { Box } from '@mui/system';
import Image from 'mui-image';
import {useSelector} from "react-redux";

function BasicConfirmModal({message, activateBasicConfirmModalState, setActivateBasicConfirmModalState, handleConfirm }) {

    const { currentTheme } = useSelector((state) => state.theme);

    const handleClose = () => {
        setActivateBasicConfirmModalState(false);
    };

    return (
        <Dialog
            open={activateBasicConfirmModalState}
            onClose={handleClose}
            PaperProps={{ sx: { width: "35%", borderRadius: "0px" } }}
            sx={{
                backdropFilter: "blur(25px)",
            }}
        >
            <DialogContent sx={{ p: 4 }}>
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Box sx={{ width: 100, height: 100 }}>
                        <Image src={Logo} alt='logo' />
                    </Box>
                </Box>
                <DialogTitle id="alert-dialog-title" textAlign="center" fontWeight={"bold"} fontSize={"25px"}>
                    {"Confirm"}
                </DialogTitle>
                <DialogContentText id="alert-dialog-description" textAlign="center" color={"#6F7288"}>
                    {message}
                </DialogContentText>
            </DialogContent>
            <Divider sx={{ borderColor: currentTheme === "dark" ? '#11121D' : '#D9D9D9' }} variant="fullWidth" />
            <DialogActions sx={{ padding: "0px" }}>
                <ButtonGroup fullWidth size='large' aria-label="outlined primary button group">
                    <Button
                        variant='flat'
                        onClick={handleClose}
                        style={{ textTransform: "none", borderRadius: "0px" }}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant='contained'
                        onClick={handleConfirm}
                        sx={{ textTransform: "none", borderRadius: "0px" }}
                    >
                        Confirm
                    </Button>
                </ButtonGroup>
            </DialogActions>
        </Dialog>
    );
}

export default BasicConfirmModal;