import React, { useState } from "react";
import {  Box, Modal, TextField, Typography, IconButton } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import lock from "../../assets/images/icons/svg/lock.svg";
import { useNavigate } from "react-router-dom";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useForm } from "react-hook-form";
import { LoadingButton } from "@mui/lab";
import { useParams } from "react-router-dom";
import Image from "mui-image";
import { useSelector } from "react-redux";

import logoBlack from "../../assets/images/logo-black.svg";
import logo from "../../assets/images/logo.svg";
import { apiUrl } from "../../config/config";

const ResetPassword = (forgotPasswordModalOpen, handleForgotPasswordModalClose) => {
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const { currentTheme } = useSelector((state) => state.theme);

  const [passwordMode, setPasswordMode] = useState({
    password: "password",
    confirmPassword: "password",
  });

  const { register, reset, handleSubmit, formState: { errors } } = useForm({
    mode: 'onChange'
  });

  const handleClick = () => {
    reset();
    setIsSuccess(false);
    navigate('/');
  }

  const handlePasswordMode = (state) => {
    let passwordModeDummy = { ...passwordMode };
    if (passwordModeDummy[state] === "password") {
      passwordModeDummy[state] = "text";
    } else {
      passwordModeDummy[state] = "password";
    }
    setPasswordMode(passwordModeDummy);
  };

  let token = useParams().token;
  const onSubmit = async (values) => {
    const formData = {
      password: values.pass,
      confirmPassword: values.confirm_pass,
      token: token
    };

    setIsSubmitting(true);

    try {
      const response = await fetch(apiUrl + "all/auth/reset-password", {
        method: 'PUT',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message);
      }

      const data = await response.json();
      console.log(data);
      setIsSubmitting(false);
      setIsSuccess(true);

      // Add any additional logic here if needed

    } catch (error) {
      console.error(error);
      setIsSubmitting(false);
      // Handle other error logic, e.g., show an error message

      // Optionally, you can rethrow the error if you want to propagate it further
      // throw error;
    }
  };


  return (
    <Modal
      open={forgotPasswordModalOpen}
      onClose={handleClick}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{
        backdropFilter: "blur(15px)",
      }}
    >
      {isSuccess ?
        <>
          <Box
            className="form-container-resetpassword"
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              p: 4,
              maxWidth: 500,
              width: "100%",
              textAlign: "center",
              backgroundColor: "white",
              borderRadius: 4,
            }}
          >
            <Box className="login-title" sx={{ mb: 4 }}>
              <Box className="login-logo">
                <img
                  src={currentTheme === "light" ? logoBlack : logo}
                  alt="logo"
                  style={{ height: 100 }}
                />
              </Box>
              <Box
                sx={{
                  textAlign: "center",
                  fontSize: [20, 24],
                  lineHeight: "30px",
                  mt: 1,
                }}
                className="logo-title"
              >
                Curate, Visualize, Activate
              </Box>
            </Box>

            <Typography variant="body1" sx={{ mb: 2, color: currentTheme === 'dark' ? '#FFF' : '#000' }}>
              Your password has been successfully updated. You can now use your new password to sign in.
            </Typography>

            <Box sx={{ mt: 4 }}>
              <Typography variant="body2" sx={{ mb: 2, mt: 20, color: currentTheme === 'dark' ? '#FFF' : '#000' }}>
                © Allpings
              </Typography>
              <Typography variant="body2" sx={{ color: currentTheme === 'dark' ? '#FFF' : '#000' }}>
                Reach us at{" "}
                <a
                  href="mailto:cr@memob.com"
                  style={{ color: "inherit", textDecoration: "underline" }}
                >
                  cr@memob.com
                </a>
              </Typography>
              <a
                href="/"
                onClick={handleClick}
              >
                Go back
              </a>
            </Box>
          </Box>
        </>
        :
        <>
          <Box
            className="form-container-resetpassword"
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              p: 4,
              maxWidth: 400,
              width: "100%",
              textAlign: "center",
              backgroundColor: "white",
              borderRadius: 4,
            }}
          >
            <Box className="login-title" sx={{ textAlign: 'center', mb: 6, mt: 2, fontSize: 24, lineHeight: '40px', fontWeight: 700 }}>Set up new password</Box>
            <Box
              component='form'
              noValidate
              autoComplete='off'
              onSubmit={handleSubmit(onSubmit)}
            >
              <Box>
                <Box className="form-label" sx={{ fontSize: 16, fontWeight: 400, mb: 3 }}>New Password</Box>
                <TextField
                  required
                  fullWidth
                  sx={{ mb: 4 }}
                  type={passwordMode.password}
                  variant="standard"
                  placeholder="Password"
                  error={!!errors['pass']}
                  helperText={errors['pass'] ? errors['pass'].message : ''}
                  {...register("pass", {
                    required: "Password required"
                  })}
                  InputProps={{
                    style: { paddingBottom: "12px", fontSize: "18px" },
                    className: "form-control",
                    startAdornment: (
                      <InputAdornment position="start" sx={{ ml: 1, mr: 2 }}>
                        <Image src={lock} alt="lock" style={{ width: 20, height: 20, objectFit: 'contain' }} />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => handlePasswordMode("password")}
                          aria-label="toggle password"
                          edge="end"
                          sx={{ ml: 2, mr: 0.5, color: "grey" }}
                        >
                          {passwordMode.password === "password" ? (
                            <VisibilityOffIcon />
                          ) : (
                            <VisibilityIcon />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>

              <Box>
                <Box className="form-label" sx={{ fontSize: 16, fontWeight: 400, mb: 3 }}>Confirm Password</Box>
                <TextField
                  required
                  fullWidth
                  sx={{ mb: 4 }}
                  type={passwordMode.confirmPassword}
                  variant="standard"
                  placeholder="Password"
                  error={!!errors['confirm_pass']}
                  helperText={errors['confirm_pass'] ? errors['confirm_pass'].message : ''}
                  {...register("confirm_pass", {
                    required: "Confirm Password required"
                  })}
                  InputProps={{
                    style: { paddingBottom: "12px", fontSize: "18px" },
                    className: "form-control",
                    startAdornment: (
                      <InputAdornment position="start" sx={{ ml: 1, mr: 2 }}>
                        <Image src={lock} alt="lock" style={{ width: 20, height: 20, objectFit: 'contain' }} />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => handlePasswordMode("confirmPassword")}
                          aria-label="toggle password"
                          edge="end"
                          sx={{ ml: 2, mr: 0.5, color: "grey" }}
                        >
                          {passwordMode.confirmPassword === "password" ? (
                            <VisibilityOffIcon />
                          ) : (
                            <VisibilityIcon />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
              <Box sx={{ textAlign: 'center' }}>
                <LoadingButton
                  variant='contained'
                  type='submit'
                  loading={isSubmitting}
                  className="form-button"
                  sx={{ py: '0.8rem', mt: 3.5, textTransform: 'capitalize', fontSize: 18, fontWeight: 700, borderRadius: 1.5, width: '90%' }}
                >
                  Update Password
                </LoadingButton>
              </Box>
            </Box>
          </Box>
        </>
      }
    </Modal>
  );
};

export default ResetPassword;
